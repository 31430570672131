import React, { useEffect } from "react";
import "./HeroSection.css";
import { IoMdArrowForward } from "react-icons/io";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import eligiblity from "../../Assets/Images/loan-eligibility.png";
import { FaArrowRight } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Goal() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem("id", 1);
    navigate("/providedetails");
  };
  const handleClick2 = () => {
    localStorage.setItem("id", 2);
    navigate("/providedetails");
  };
  const handleClick3 = () => {
    localStorage.setItem("id", 3);
    navigate("/providedetails");
  };
  useEffect(() => {
    const from = localStorage.getItem("from");
    {
      from == null && navigate("/login");
    }
  }, []);
  return (
    <>
      <div className="HeroSection">
        <div className="HeroHeading">Minimize your EMIs with MinEMI</div>
        <div
          className="SubHeading"
          style={{
            fontSize: "16px",
            fontWeight: "800",
            paddingTop: "24px",
            fontFamily: "Archivo",
          }}
        >
          Are you Paying more EMIs than you should?
          <p style={{ color: "rgb(0, 153, 100)" }}>With MinEMI you can,</p>
        </div>

        <div className="HeroContainer">
          <div className="lowerEmi">
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Lower Your EMIs
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <IoMdArrowForward
                  size={32}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  onClick={handleClick}
                />
              </div>
              <div className="Illustration">
                <img
                  src={lowerEmiIllustration}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="consolidate">
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Club Multiple EMIs
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <IoMdArrowForward
                  size={32}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  onClick={handleClick2}
                />
              </div>
              <div className="ConsolidateIllustration">
                <img src={ConsolidateIllustration} alt="" />
              </div>
            </div>
          </div>
          <div className="TopUp">
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Get New Loan with lower EMI
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <IoMdArrowForward
                  size={32}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  onClick={handleClick3}
                />
              </div>
              <div className="TopUPIllustration">
                <img src={TopUPIllustration} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="HeroContainerMobile">
          <Link
            onClick={handleClick}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={lowerEmiIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Lower Your EMIs
                </div>
                <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Use our algorithm to reduce your loan interest rate based on
                your credit score.
              </div>
            </div>
          </Link>
          <Link
            onClick={handleClick2}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={ConsolidateIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Club Multiple EMIs
                </div>
                <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Review and consolidate high-interest loans and overdraft
                accounts affecting your credit profile.
              </div>
            </div>
          </Link>
          <Link
            onClick={handleClick3}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={TopUPIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Get New Loan with lower EMI
                </div>
                <FaArrowRight
                  // onClick={handleClick3}
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Get extra funds easily to chase new goals and opportunity.
              </div>
            </div>
          </Link>
          <Link
            onClick={handleClick3}
            to="https://minemi.ai/personal-loan/check-eligibility/"
             target="_blank"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={eligiblity}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Discover Your Loan Eligibility
                </div>
                <FaArrowRight
                  // onClick={handleClick3}
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Explore  your loan potential with no impact on your credit score
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Goal;
