import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Images/Logo.webp";
import facebook from "../../Assets/Images/facebook.webp";
import instagram from "../../Assets/Images/instagram.webp";
import feedback from "../../Assets/Images/feedback.webp";
import x from "../../Assets/Images/x.webp";
import "./Footer.css";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BorderAllTwoTone } from "@mui/icons-material";

const Footer = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  // const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [htmlContent, setHtmlContent] = useState("");
  const [indicator, setIndicator] = useState(false);
  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleChange = (e) => {
    setError(false);
    const inputText = e.target.value;
    setMessage(inputText);
    if (inputText.length <= 1000) {
      setMessage(inputText);
    }
  };
  const Contactus = () => {
    if (message === "") {
      setError(true);
    } else {
      setIndicator(true);
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SECRET_KEY}/submit/contactForm`,
        data: { message: message },
      };

      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          toast(response.data.message);
          setIndicator(false);
          setMessage("");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  const [token, setToken] = useState(null);

  const fetchToken = () => {
    const savedToken = localStorage.getItem("token");
    // console.log("token in footer", savedToken);
    setToken(savedToken);
  };

  useEffect(() => {
    CompanyDetail();
    fetchToken();
  }, []);
  // if (token) {
  //   return null;
  // }
  // console.log(location.pathname);
  const pathsToExclude = ["/login", "/", "/otp","/faq"];

  return (
    <>
      {/* {pathsToExclude.includes(location.pathname) ? ( */}
        <footer
          // style={{ border: "1px solid red" }}
          className="footer footer_type_1"
          style={{marginTop:'4%'}}
        >
          <ToastContainer />
          <div className="footer-middle container">
            <div className="row">
              <div
                className="footer-column footer-store-info col-12 mb-4 mb-lg-0 "
                style={{ paddingRight: "20px" }}
              >
                <div className="logo logo-footer" >
                  <Link to="/dashboard" >
                    <img
                      src={Logo}
                   alt=""
                      className="logo__image d-block"
                    />
                  </Link>
                </div>
                {/* <p
                  className=" address footer-address "
                  style={{ display: "block" }}
                >
                  Get in touch
                </p>
                <div className=" newsletter m-0 d-block">
                  <p
                    className="fw-medium"
                    style={{
                      marginTop: "0px",
                      marginBottom: "15px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "17px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    If you have any feedback, issues, or grievances or want to
                    connect with us, please share them below.
                  </p>
                </div> */}
                {/* <div
              className="inputs"
              style={{
                border: "1px solid rgba(138, 138, 138, 1)",
                borderRadius: "10px",
                width: "400px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "4px",
                marginTop: "0px",
                backgroundColor: "#fff",
                height: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  backgroundColor: "#fff",
                  width:'100%'
                }}
              >
                <img
                  src={Email}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#fff",
                  }}
                />
                <input
                  type="text"
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "#fff",
                    width:'100%'
                  }}
                  placeholder="Enter Email"
                />
              </div>
            </div> */}
                {/* <div className="inputs">
                  <div
                    style={{
                      border: "1px solid rgba(138, 138, 138, 1)",
                      borderRadius: "10px",
                      width: "390px",
                      padding: "4px",
                      paddingTop: "5%",
                      paddingLeft: "3%",
                      marginTop: "8px",
                      backgroundColor: "#fff",
                      height: "100px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <textarea
                      value={message}
                      placeholder="Write your comments"
                      onChange={handleChange}
                      style={{
                        border: "none",
                        outline: "none",
                        backgroundColor: "#fff",
                        resize: "vertical",
                        width: "100%",
                        height: "80%",
                      }}
                    ></textarea>
                    <div
                      className="character-count"
                      style={{
                        textAlign: "end",
                        backgroundColor: "#fff",
                        alignSelf: "end",
                        paddingBottom: "3%",
                        paddingRight: "2%",
                        color: "#8A8A8A",
                        fontWeight: "400",
                        fontSize: 13,
                      }}
                    >
                      {message.length}/1000
                    </div>
                  </div>
                </div> */}
                {/* {error && (
                  <div
                    className="inputs"
                    style={{ color: "red", fontSize: 13, marginTop: "1%" }}
                  >
                    Please Provide Valid Message
                  </div>
                )} */}
{/* 
                <div
                  onClick={() => Contactus()}
                  className="inputs"
                  style={{
                    backgroundColor: "#59266D",
                    width: 129,
                    height: 40,
                    borderRadius: 8,
                    fontSize: 16,
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#FFFFFF",
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {indicator ? (
                    <div className="spinner-container">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </div> */}
              </div>
              <div className="border"></div>

              <div className="footer-column footer-menu mb-4 ">
                <h5 className="sub-menu__title ">Services</h5>
                <ul className="sub-menu__list list-unstyled">
                  <li className="sub-menu__item">
                    <Link
                      to="/balancetransfer"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s subitem-1-item"
                    >
                      Balance Transfer
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/loanconsolidation"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Loan Consolidation
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/topup"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Top-up Running Loans
                    </Link>
                  </li>
                </ul>
                <div className="border"></div>
              </div>
              <div className="footer-column footer-menu mb-4 ">
                <h5 className="sub-menu__title t">Quick links</h5>
                <ul className="sub-menu__list list-unstyled">
                  <li className="sub-menu__item subitem-1-item">
                    <Link
                      to="/aboutus"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/contactus"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/blog"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Blogs
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/privacypolicy"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/terms&condition"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/faq"
                      onClick={scrollToTop}
                      className="menu-link menu-link_us-s"
                    >
                     FAQ
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="https://minemi.ai/personal-loan/check-eligibility/"
                      target="_blank"
                      className="menu-link menu-link_us-s"
                    >
                     Personal Loan Eligibility Check
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="https://minemi.ai/home-loan/check-eligibility/"
                       target="_blank"
                      className="menu-link menu-link_us-s"
                    >
                      Home Loan Eligibility Check
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="https://minemi.ai/car-loan/check-eligibility/"
                       target="_blank"
                      className="menu-link menu-link_us-s"
                    >
                     Car Loan Eligibility Check
                    </Link>
                  </li>
                  <div className="border" style={{marginTop:10}}></div> 
                  <li className="sub-menu__title" style={{marginTop:10}}>
                    <h5 className="sub-menu__title"><a style={{color:"black"}} href="https://minemi.ai/calculators/">Financial Calculators</a></h5>
                  </li>
                  
                </ul>
              <div className="border"></div>
              </div>

              <div className="footer-column footer-menu mb-4 ">
                <h5 className="sub-menu__title ">Contact Us</h5>
                <ul className="sub-menu__list list-unstyled">
                  <li className="sub-menu__item">
                    <Link
                      className="menu-link subitem-1-item"
                      style={{ display: "flex" }}
                    >
                      <span>
                        <FiPhone
                          width="18px"
                          height="18px"
                          style={{ alignItems: "center" }}
                        />
                      </span>
                      <span
                        className="menu-link_us-s"
                        style={{ paddingLeft: "8px" }}
                      >
                        {htmlContent.contact_no_text}
                      </span>
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link className="menu-link " style={{ display: "flex" }}>
                      <span
                        style={{ alignItems: "center", alignSelf: "center" }}
                      >
                        <MdOutlineEmail
                          width="18px"
                          height="14px"
                          style={{ alignItems: "center", alignSelf: "center" }}
                        />
                      </span>
                      <span
                        className="email-1 menu-link_us-s"
                        style={{ paddingLeft: "8px" }}
                      >
                        {" "}
                        {htmlContent.email_text}
                      </span>
                    </Link>
                  </li>
                  <li className="sub-menu__item">
                    <Link
                      to="/GrievanceAddressal"
                      onClick={scrollToTop}
                      className="menu-link "
                      style={{ display: "flex" }}
                    >
                      <span
                        style={{
                          alignItems: "center",
                          alignSelf: "center",
                          width: 20,
                          height: 20,
                        }}
                      >
                        <img
                          src={feedback}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            opacity: 0.5,
                          }}
                        />
                      </span>
                      <span
                        className="email-1 menu-link_us-s"
                        style={{ paddingLeft: "8px" }}
                      >
                        Grievance Addressal
                      </span>
                    </Link>
                  </li>
                </ul>
                <ul
                  className="social-links list-unstyled d-flex flex-wrap mb-0"
                  style={{ marginTop: "15px" }}
                >
                  <li style={{ marginLeft: "15px" }}>
                    <Link to={htmlContent.twitter_link}>
                      <img
                        className="footer__social-link d-block"
                        src={x}
                        height="32px"
                        width="32px"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li style={{ marginLeft: "15px" }}>
                    <Link to={htmlContent.insta_link}>
                      <img
                        className="footer__social-link d-block"
                        src={instagram}
                        height="32px"
                        width="32px"
                        alt=""
                      />
                    </Link>
                  </li>
                  <li style={{ marginLeft: "15px" }}>
                    <Link to={htmlContent.fb_link}>
                      <img
                        className="footer__social-link d-block"
                        src={facebook}
                        height="32px"
                        width="32px"
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
                <div className="border"></div>
              </div>
              {/* <p
                className=" footer-view footer-address"
                style={{ marginTop: "20px" }}
              >
                Get in touch
              </p>

              <div className=" update m-0">
                <p
                  className="fw-medium"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  Subscribe to our newsletter to receive updates on your
                  upcoming loans, other more services updated in the portal.
                </p>
              </div> */}
              {/* <div
            className="field message-boxes"
            style={{
              border: "1px solid rgba(138, 138, 138, 1)",
              borderRadius: "10px",
              width: "420px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "4px",
              marginTop: "8px",
              backgroundColor: "#fff",
              marginLeft: "16px",
              marginRight: "16px",
              height: "48px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                backgroundColor: "#fff",
                width:'100%'
              }}
            >
              <img
                src={Email}
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "#fff",
                }}
              />
              <input
                type="text"
                style={{
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                  width:'100%'
                }}
                placeholder="Enter Email"
              />
            </div>
          </div> */}
              {/* <div
                className="field message-box"
                style={{
                  border: "1px solid rgba(138, 138, 138, 1)",
                  borderRadius: "10px",
                  width: "410px",
                  // display: "flex",
                  // justifyContent: "space-between",
                  // alignItems: "center",
                  padding: "4px",
                  paddingTop: "5%",
                  paddingLeft: "3%",
                  marginTop: "8px",
                  backgroundColor: "#fff",
                  height: "100px",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "16px",
                }}
              >
                <textarea
                  value={message}
                  placeholder="Write your comments"
                  onChange={handleChange}
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "#fff",
                    resize: "vertical",
                    width: "100%",
                    height: "80%",
                  }}
                ></textarea>
                <div
                  className="character-count"
                  style={{
                    textAlign: "end",
                    backgroundColor: "#fff",
                    alignSelf: "end",
                    paddingBottom: "3%",
                    paddingRight: "2%",
                    color: "#8A8A8A",
                    // fontFamily: "Archivo-Regular",
                    fontSize: 13,
                    fontWeight: "400",
                  }}
                >
                  {message.length}/1000
                </div>
              </div> */}
            </div>
            {/* {error && (
              <div
                className="field"
                style={{
                  color: "red",
                  marginLeft: "3%",
                  marginTop: "2%",
                  fontSize: 12,
                }}
              >
                Please Provide Valid Message
              </div>
            )}

            <div
              onClick={() => Contactus()}
              className="field"
              style={{
                backgroundColor: "#59266D",
                width: 129,
                height: 40,
                borderRadius: 8,
                fontSize: 16,
                fontWeight: "700",
                textAlign: "center",
                color: "#FFFFFF",
                marginTop: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "16px",
              }}
            >
              {indicator ? (
                <div className="spinner-container">
                  <div className="spinner"></div>
                </div>
              ) : (
                "Submit"
              )}
            </div> */}
          </div>

          <div className="footer-bottom container">
            <span className=" text-center footer-copyright me-auto">
              © 2024 MinEMI. All rights reserved.
            </span>
          </div>
        </footer>
      {/* ) : null} */}
    </>
  );
};

export default Footer;
