import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/Header/NavBar";
import Login from "./Pages/LoginSignUp/Login";
import Otp from "./Pages/LoginSignUp/Otp";
import ProvideDetails from "./Pages/LoginSignUp/ProvideDetails";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import HomeAfterLogin from "./Pages/Home/HomeAfterLogin";
import AboutUs from "./Pages/Home/AboutUs";
import ContactUs from "./Pages/Home/ContactUs";
import Confirm from "./Pages/Home/Confirm";
import UpdateProfile from "./Pages/Home/UpdateProfile";
import ConsolidDetail from "./Pages/Home/ConsolidDetail";
import TopUpDetail from "./Pages/Home/TopUpDetail";
import LowerEmiDetail from "./Pages/Home/LowerEmiDetail";
import Form from "./Pages/Home/Form";
import Status from "./Pages/Home/Status";
import Loan from "./Pages/Home/Loan";
import LowerEmiDetailPage from "./Pages/Home/LowerEmiDetailPage";
import Profession from "./Pages/Home/Profession";
import LoanEdit from "./Pages/Home/LoanEdit";
import Goal from "./Pages/Home/Goal";
import Test from "./Pages/Home/Test";
import Form2 from "./Pages/Home/Form2";
import Statuss from "./Pages/Home/Statuss";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import TermsandCondition from "./Pages/Home/TermsandCondition";
import ExperianConsent from "./Pages/Home/ExperianConsent";
import { isMobile } from "mobile-device-detect";
import GrievanceAddressal from "./Pages/Home/GrievanceAddressal";
import BalanceTransfer from "./Pages/Home/BalanceTransfer";
import Consolidate from "./Pages/Home/Consolidate";
import Topup from "./Pages/Home/Topup";
import Blog from "./Pages/Home/Blog";
import BlogDetail from "./Pages/Home/BlogDetail";
import PersonalLoan from "./Pages/StaticPage/PersonalLoan";
import BadCredit from "./Pages/StaticPage/BadCredit";
import Homeloan from "./Pages/StaticPage/Homeloan";
import CreditCard from "./Pages/StaticPage/CreditCard";
import Carloan from "./Pages/StaticPage/Carloan";
import Faq from "./Pages/StaticPage/Faq";
import EligiblityCriteria from "./Pages/StaticPage/EligiblityCriteria";
import HdfcEligiblity from "./Pages/StaticPage/HdfcEligiblity";
import LoanTracking from "./Pages/StaticPage/LoanTracking";
import LoanPage from "./Pages/StaticPage/LoanPage";
import AxisEligiblity from "./Pages/StaticPage/AxisEligiblity";
import SbiEligiblity from "./Pages/StaticPage/SbiEligiblity";
import IciciEligiblity from "./Pages/StaticPage/IciciEligiblity";
import PersonalTopup from "./Pages/StaticPage/PersonalTopup";
import Typesofpersonalloan from "./Pages/StaticPage/Typesofpersonalloan";
import BuildNavbar from "./Components/Header/BuildNavbar";
import NotFound from "./Pages/StaticPage/NotFound";



const App = () => {
  const [isMobile, setIsMobile] = useState(true);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null) {
      if (!isMobile) {
        //DESKTOP DETECTED LOGOUT USER
        localStorage.removeItem("token");
        window.location.href = "/";
        return;
      } else {
        return () => {};
      }
    }
  }, [isMobile]);

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div>
      <Router scrollRestoration={true}>
        <NavBar />
        {/* <BuildNavbar/> */}
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/confirmloan" element={<LoanEdit />} />
          <Route path="/savingopportunity" element={<LowerEmiDetailPage />} />
          <Route path="/details" element={<LowerEmiDetail />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/dashboard" element={<HomeAfterLogin />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/providedetails" element={<ProvideDetails />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/loanoverview" element={<Loan />} />
          <Route path="/test" element={<Test />} />
          <Route path="/topupdetail" element={<TopUpDetail />} />
          <Route path="/personalloanform" element={<Form />} />
          <Route path="/homeloanform" element={<Form2 />} />
          <Route path="/applicationstatus" element={<Statuss />} />
          <Route path="/statusdetail" element={<Status />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms&condition" element={<TermsandCondition />} />
          <Route path="/experianconsent" element={<ExperianConsent />} />
          <Route path="/GrievanceAddressal" element={<GrievanceAddressal />} />
          <Route path="/balancetransfer" element={<BalanceTransfer />} />
          <Route path="/loanconsolidation" element={<Consolidate />} />
          <Route path="/topup" element={<Topup />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogDetail/:slug" element={<BlogDetail />} />
          <Route path="/personalLoan" element={<PersonalLoan />} />
          <Route path="/homeloan" element={<Homeloan />} />
          <Route path="/badcredit" element={<BadCredit />} />
          <Route path="/creditcard" element={<CreditCard />} />
          <Route path="/carloan" element={<Carloan />} />
          <Route path="/loanpage" element={<LoanPage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/loan-eligibilty-criteria" element={<EligiblityCriteria />} />
          <Route path="/hdfc-loan-eligiblity" element={< HdfcEligiblity/>} />
          <Route path="/axis-loan-eligiblity" element={< AxisEligiblity/>} />
          <Route path="/sbi-loan-eligiblity" element={< SbiEligiblity/>} />
          <Route path="/icici-loan-eligiblity" element={< IciciEligiblity/>} />
          <Route path="/loan-tracking" element={<LoanTracking/>} />
          <Route path="/personal-topup" element={<PersonalTopup/>} />
          <Route path="/types-of-personalloan" element={<Typesofpersonalloan/>} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
