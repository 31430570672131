import React from 'react'
import image from "../../Assets/Images/axis.webp"
import { LuDot } from 'react-icons/lu'
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


const AxisEligiblity = () => {
  const criteriaData = [
    {
      factor: "Income",
      requirement: "₹25,000/month (minimum for salaried individuals)",
      impact: "Higher income increases loan eligibility and the amount you can borrow.",
    },
    {
      factor: "Credit Score",
      requirement: "700+",
      impact: "A higher score qualifies you for better loan terms and lower interest rates.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried or self-employed (minimum 2 years)",
      impact: "Stable employment improves chances; self-employed individuals may need more documentation.",
    },
    {
      factor: "Fixed Obligations to Income Ratio (FOIR)",
      requirement: "40%-50%",
      impact: "A lower FOIR indicates fewer existing EMIs and improves loan eligibility.",
    },
    {
      factor: "Age",
      requirement: "21 to 60 years",
      impact: "Younger applicants have a longer tenure but must show steady income.",
    },
  ];

  return (
    <>
    <div
      style={{
        backgroundColor: "#E7E4F9",
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 40,
        paddingBottom: 40,
        overflow: "hidden",
        boxShadow: "2px 1px 2px 0px #00000040",
      }}
    >
      <div
        className="homeloan-heading"
        style={{
          fontFamily: "Archivo",
          fontWeight: 700,
          fontSize: 40,
          color: "#59266D",
          backgroundColor: "#E7E4F9",
        }}
      >
        {" "}
        AXIS Bank Personal Loan Eligibility
      </div>
      <p
        className="homeloan-paragraph"
        style={{
          fontFamily: "Archivo",
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1,
        }}
      >
      Check your eligibility for an AXIS Bank personal loan. Learn about the credit score, income, age, and other criteria to qualify for the best loan offers from AXIS.
      </p>  
     
    </div>
    <div  className="container" style={{marginLeft:'5%',marginRight:'5%',marginTop: 30 }}>
        <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Personal Loan Eligibility Criteria
        </p>
        <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 400,
            fontSize: 16,
            color: "#8A8A8A",
            marginTop: 8,
          }}
        >
         Lorem ipsum dolor sit amet consectetur. Cras diam blandit morbi maecenas laoreet lorem scelerisque enim morbi.
        </p>
        <div
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: 24,
        marginTop: 24,
        backgroundColor: "white",
        marginBottom: "2%",
        paddingBottom: 24,
        maxWidth: "100%",
      }}
    >
      <div style={{ backgroundColor: "white", borderTopLeftRadius: 24, borderTopRightRadius: 24 }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 16,
            color: "#59266D",
            padding: "16px",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            textAlign: "center",
          }}
        >
          AXIS Loan Eligibility Criteria
        </div>

        <div
          style={{
            backgroundColor: "white",
            borderRadius: 24,
            paddingTop: 24,
            paddingBottom: 24,
            display: "flex",
            paddingLeft:'1%'
          }}
        >
          <div style={{ width: 141, height: 62 }}>
            <img src={image} alt="AXIS Logo" style={{ width: "100%", height: "100%",backgroundColor:'white' }} />
          </div>
        </div>
      </div>

      <div style={{ padding: "0 1.5%", maxWidth: "100%", overflowX: "auto" ,backgroundColor:'white'}}>
        <table className='table-class'
          style={{
            fontFamily: "Archivo",
            borderCollapse: "collapse",
            borderRadius: 12,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "left",
          }}
        >
          <thead className='thead-class'>
            <tr>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Eligibility Factor
              </th>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Minimum Requirement
              </th>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Impact on Loan Eligibility
              </th>
            </tr>
          </thead>
          <tbody>
            {criteriaData.map((row, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white" }}>
                <td  className='td-class'style={{ padding: "16px", fontWeight: 700 }}>{row.factor}</td>
                <td className='td-class' style={{ padding: "16px" }}>{row.requirement}</td>
                <td className='td-class' style={{ padding: "16px" }}>{row.impact}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Eligibility Criteria for AXIS Bank Personal Loan     </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
To qualify for an AXIS Bank personal loan, you need to meet certain eligibility criteria related to age, income, credit score, and employment status. Here’s a closer look at the detailed requirements.   </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Age:</span>
          21 to 60 years for salaried individuals, 25 to 65 years for self-employed individuals.          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Minimum Monthly Income:</span>
          ₹25,000 for salaried individuals in metro cities; annual income of ₹1.5 lakhs for self-employed. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Credit Score:</span>
           A score of 700 or above increases approval chances.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Employment/Business Stability: </span>
         Minimum 2 years of work experience (salaried) with 1 year in the current job; 3 years in current business for self-employed. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Loan Amount:</span>
           AXIS offers personal loans up to ₹25,00,000, based on eligibility. </div>
            </div>
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Documents Required for AXIS Bank Personal Loan   </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
Gather the required documents to ensure a smooth application process. Here’s what you’ll need to apply for an AXIS Bank personal loan.</div>

<div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>  Identity Proof: </span>
         Aadhaar card, PAN card, passport, voter ID, or driving license.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Address Proof:</span>
           Utility bills, rental agreement, or passport </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <div style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Income Proof:</div>
           <div><span style={{paddingLeft:'1%',color:'black' ,color: "#1F1F1F", fontWeight: 500, fontSize: 15 }}>• Salaried:</span> Latest 3 months’ salary slips and bank statements for the last 6 months.</div>
            <div style={{paddingLeft:'1%'}}><span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 15,marginTop:5}}>• Self-Employed:</span> Income tax returns for the last 2 years and audited financials.</div></div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Employment/Business Stability: </span>
         Minimum 2 years of work experience (salaried) with 1 year in the current job; 3 years in current business for self-employed. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Photographs:</span>
           Recent passport-sized photos.</div>
            </div>
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
How to Improve Your Eligibility for AXIS Bank Personal    </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
If you want to boost your chances of qualifying for the best offers, consider these steps to improve your personal loan eligibility.</div>

<div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Increase Your Credit Score:</span>
           Maintain a score above 700 by paying bills on time and reducing outstanding debts.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Lower Your Debt-to-Income Ratio:</span>
          Reducing existing loan commitments helps you meet AXIS Bank’s FOIR (Fixed Obligation to Income Ratio) requirements.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Choose a Shorter Tenure:</span>
          Opting for a shorter loan tenure can improve eligibility, as it reflects better repayment capacity.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Add a Co-Applicant:</span>
          Applying with a co-applicant with a strong credit profile can enhance your eligibility.</div>
            </div>
        </div>
        
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Frequently Asked Questions (FAQs) on AXIS Bank Personal Loan Eligibility  </div>
    <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
          defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",}}
            >
              Accordion 1
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div  className="increase-margines" style={{backgroundColor:'#E7E4F9',borderRadius:24,padding:40,marginTop:'2%',marginBottom:'4%'}}>
            <div style={{display:'flex',flexDirection:'column',gap:8,backgroundColor:'transparent'}}>
            <p className="minutes"style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:32,fontWeight:600}}>Check Your Eligibility in Minutes!</p>
            <p style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:18,fontWeight:400,lineHeight:1.2}}>Get personalized loan options based on your income, current EMI, and credit score. Quick, easy,<br/> and tailored just for you—apply now to see what you qualify for!</p>
            <Link to="https://minemi.ai/personal-loan/check-eligibility"  style={{display:'flex',flexDirection:'row',gap:24,backgroundColor:'transparent',marginTop:'2%',cursor:'pointer'}}>
                <div className="buttom-loan-eligiblity-one" style={{backgroundColor:'#553285',color:'white',borderRadius:8,padding:10, paddingLeft:40,paddingRight:40,fontFamily:'Archivo',fontWeight:500,fontSize:18}} > Check Your Eligibility Now</div>

            </Link>
            </div>

        </div>
    </div>
    

    </>
  )
}

export default  AxisEligiblity