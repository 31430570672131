import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import axios from "axios";
import { Helmet } from 'react-helmet';


function Consolidate() {
  const [htmlContent, setHtmlContent] = useState("");

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.loan_consolidation);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <>
     <Helmet>
        <title>
        Loan Consolidation Services & Manage Debt Easily | Minemi

        </title>
        <meta
          name="description"
          content="Consolidate your loans into one with Minemi.ai. Simplify loan repayments, reduce interest rates, and gain control over your finances.
"
        />
      </Helmet>
      <div
        className="api-text"
        style={{ marginLeft: "5%", marginRight: "5%" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}

export default Consolidate;
