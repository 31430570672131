import React from 'react'
import { LuDot } from 'react-icons/lu'
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion } from '@mui/material';
import { Link } from 'react-router-dom';

const PersonalTopup = () => {
  return (
    <>
        <div
      style={{
        backgroundColor: "#E7E4F9",
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 40,
        paddingBottom: 40,
        overflow: "hidden",
        boxShadow: "2px 1px 2px 0px #00000040",
      }}
    >
      <div
        className="homeloan-heading"
        style={{
          fontFamily: "Archivo",
          fontWeight: 700,
          fontSize: 40,
          color: "#59266D",
          backgroundColor: "#E7E4F9",
        }}
      >
        {" "}
        Personal Loan Top-Up: Increase Your Loan Amount Easily
        </div>
      <p
        className="homeloan-paragraph"
        style={{
          fontFamily: "Archivo",
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1,
        }}
      >
Explore how you can top-up your existing personal loan with minimal documentation. Get more funds for your needs without the hassle of a new application.
</p>  


     
    </div>

<div style={{marginLeft:'3%',marginRight:'3%'}}>
    <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
            A personal loan top-up allows existing loan holders to borrow additional funds over and above their current loan balance. Rather than going through a new loan application, a top-up loan provides extra money with minimal documentation and processing time. Whether it's for home improvements, medical emergencies, or debt consolidation, a top-up loan offers flexibility and ease.
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          What is a Personal Loan Top-Up?
        </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
            A personal loan top-up is an additional loan that you can take out on your existing personal loan. The lender adds the top-up amount to your outstanding balance, adjusting your EMI accordingly. This means that you don't have to go through the entire loan application process again, making it a quicker and more convenient solution for those needing additional funds.
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
         Benefits of a Personal Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Minimal Documentation: </span>
         No need to resubmit all your documents. The process is quicker than a new loan application.        

          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Lower Interest Rates:</span>
          Since it's linked to your existing loan, top-up loans often come with competitive interest rates.     </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Flexible Usage:  </span>
          Use the loan for a variety of needs, from home renovation to medical expenses. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>No Need for Collateral: </span>
          Like personal loans, top-up loans do not require any security or collateral. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Extended Tenure: </span>
          Option to extend the loan tenure, reducing your monthly EMI burden.  </div>
            </div>
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
         Eligibility Criteria:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          You should have an existing personal loan with the lender for a certain period, typically 6 to 12 months.
          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          You must have a good repayment track record with no defaults or late payments.
          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          Your credit score should meet the lender’s minimum requirement, usually above 700.
          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          Your current FOIR (Fixed Obligation to Income Ratio) should allow for additional EMIs without crossing the lender’s limits.</div>
            </div>
           
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
        How to Apply for a Personal Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Check Eligibility:</span>
          Use our top-up loan eligibility calculator to see if you qualify.
          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Submit Your Application:</span>
          If eligible, submit your application through the lender’s portal or app.     </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Review by Lender:  </span>
          The lender will evaluate your repayment history and credit score before processing the top-up. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Approval and Disbursement:</span>
          Upon approval, the additional loan amount will be added to your existing balance, and your EMI will be updated.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Extended Tenure: </span>
          Option to extend the loan tenure, reducing your monthly EMI burden.  </div>
            </div>
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
      Common Uses for a Personal Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Home Renovation:</span>
          Fund your home renovation projects without applying for a new loan.
          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Medical Emergencies:</span>
          Access funds quickly for unexpected medical expenses.   </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Debt Consolidation: </span>
          Consolidate high-interest debts into one manageable EMI with a top-up loan. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Education</span>
          Cover education-related expenses, such as tuition fees, using a top-up loan.</div>
            </div>
          
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Frequently Asked Questions (FAQs) </div>
    <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
          defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",fontWeight:700}}
            >
             Can I apply for a top-up loan with any bank?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            No, top-up loans are available only with the bank or lender where your current loan is held.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12,fontWeight:700 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
Does a top-up loan require a new credit check?            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Yes, lenders will typically check your credit score and repayment history before approving a top-up loan.

            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 ,fontWeight:700}}
            >
            How long does it take to get a top-up loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Since it’s tied to your existing loan, the process is usually faster than applying for a new loan.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
       
        </div>
        <div  className="increase-margines" style={{backgroundColor:'#E7E4F9',borderRadius:24,padding:40,marginTop:64,  marginBottom:149}}>
            <div style={{display:'flex',flexDirection:'column',gap:8,backgroundColor:'transparent'}}>
            <p className="minutes"style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:32,fontWeight:600}}>Check Your Eligibility in Minutes!</p>
            <p style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:18,fontWeight:400,lineHeight:1.2}}>Get personalized loan options based on your income, current EMI, and credit score. Quick, easy,<br className="display-none"/> and tailored just for you—apply now to see what you qualify for!</p>
            <div style={{backgroundColor:'transparent',marginTop:30,display:'flex',flexDirection:'row',gap:24}}>
                <Link  to="https://minemi.ai/personal-loan/check-eligibility" style={{backgroundColor:'#553285',color:'white',borderRadius:8,padding:10, paddingLeft:50,paddingRight:50,fontFamily:'Archivo',fontWeight:500,fontSize:16}}>Check Eligiblity Now</Link>

            </div>
            </div>

        </div>
        </div>
    
    
    </>
  )
}

export default PersonalTopup