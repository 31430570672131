import React, { useEffect, useRef, useState } from 'react'
import img from "../../Assets/Images/image 48.webp";
import icici from "../../Assets/Images/icici.webp";
import axis from "../../Assets/Images/axis.webp";
import sbi from "../../Assets/Images/sbi.webp";
import bajaj from "../../Assets/Images/bajaj-finance6835.webp";
import { useNavigate } from 'react-router-dom';


const BankTable = () => {
    const [openIndex, setOpenIndex] = useState(null);
  const[index,setIndex]=useState(0)
  const BankTableRef = useRef(null);
  const navigate=useNavigate()

  useEffect(() => {
    if (window.location.hash === "#BankTable" && BankTableRef.current) {
        BankTableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (

         
<div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display:'flex',
              flexDirection:'column',gap:8,
           
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                    alt=""
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
              
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                   Interest Rate (p.a.)
                     
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >10.75% - 14.00%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    > 1 to 5 years
                    </p>
                  </div>  
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                   Processing Fees
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >	Up to 2.5%
                    </p>
                  </div>
              
                </div>
              </div>
              <div  onClick={()=>navigate("")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
             View Details
              </div>
            </div>
<div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                  alt=""
                    src={sbi}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                  Interest Rate (p.a.)
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >9.60% - 13.85% 
                    </p>
                  </div>
              
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
Loan Tenure 
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    > 1 to 7 years
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                   Processing Fees
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 1.5%
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
            View Details
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                  alt=""
                    src={icici}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
             
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
Interest Rate (p.a.)
</p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >10.50% - 16.00%
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                   1 to 6 years 
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                  Processing Fees
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 2.25%
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18

                }}
              >
           View Details
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF"}}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={axis}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
               Interest Rate (p.a.)
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    > 10.49% - 17.00% 		
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    > 1 to 5 years
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Processing Fees
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 1.75%
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              View Details
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF",borderBottom:'1px solid #D9D9D9' ,paddingBottom:15}}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={bajaj}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
               Interest Rate (p.a.)
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    > 12.00% - 17.00%		
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >  1 to 5 years 
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Processing Fees
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 3.0%
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              View Details
              </div>
            </div>
            <div style={{textAlign:'center',backgroundColor:'white',padding:5,fontFamily:'Archivo',fontWeight:600,color:'#1F1F1F',fontSize:18}}>Load More..</div>
              
      </div>
  
  )
}

export default BankTable
