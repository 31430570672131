import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { IoMdArrowForward } from "react-icons/io";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import eligiblity from "../../Assets/Images/Tiny people doing priorities checklist flat vector illustration.webp";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { HashLink as RouterHashLink  } from 'react-router-hash-link';


const HeroSection = () => {
  const texts = ["Paying more EMIs than you should?", "Paying too many different EMIs? ", "Looking to top-up your running loan?"];
  const [index, setIndex] = useState(0); 

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % texts.length); 
    }, 2000); 

    return () => clearInterval(interval); 
  }, []);
  return (
    <>
      <div className="HeroSection">
        <div className="HeroHeading">Minimize your EMIs with MinEMI</div>
        <div className="SubHeading">
          Are you <span style={
            {backgroundColor:'transparent',color:'#009964'}
          }>{texts[index]}</span> 
          <p>With MinEMI you can,</p>
        </div>

        <div className="HeroContainer">
      
          <RouterHashLink className="lowerEmi" to="#DownloadApp" >
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText" >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Lower Your EMIs
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <RouterHashLink  to="#DownloadApp" style={{backgroundColor:'transparent'}}>  <IoMdArrowForward
                  size={32}
                  color={'#59266D'}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                />
                </RouterHashLink> 
              </div>
              <div className="Illustration">
                <img
                  src={lowerEmiIllustration}
                  alt="img1"
                />
              </div>
            </div>
          </RouterHashLink>
          <RouterHashLink to="#DownloadApp"  className="consolidate">
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Club Multiple EMIs
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
              
                <RouterHashLink  to="#DownloadApp" style={{backgroundColor:'transparent'}}>  <IoMdArrowForward
                  size={32}
                  color={'#59266D'}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                /> </RouterHashLink> 
              
              </div>
              <div className="ConsolidateIllustration">
                <img src={ConsolidateIllustration} alt="" />
              </div>
            </div>
          </RouterHashLink>
          <RouterHashLink to="#DownloadApp"  className="TopUp">
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Get New Loan with lower EMI
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <RouterHashLink  to="#DownloadApp" style={{backgroundColor:'transparent'}} >

                <IoMdArrowForward
                  size={32}
                  color={'#59266D'}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                />
                </RouterHashLink >
              </div>
              <div className="TopUPIllustration">
                <img src={TopUPIllustration} alt=""   />
              </div>
            </div>
          </RouterHashLink>
          <Link to="https://minemi.ai/personal-loan/check-eligibility/"  target="_blank" className="lowerEmi" >
            <div className="Section">
              <div className="InnerSection">
                <div className="InnerSectionText" >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "21px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Discover Your Loan Eligibility
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Explore  your loan potential with no impact on your credit score.
                  </div>
                </div>
                <IoMdArrowForward
                  size={32}
                  color={'#59266D'}
                  style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                />
          
              </div>
              <div className="Illustration">
                <img
                  src={eligiblity}
                  alt=""
                  style={{ width: "100%", height: "100%"  , backgroundColor: "transparent",
                    mixBlendMode:'multiply' }}
                />
              </div>
            </div>
          </Link>
          
        </div>
        
        <div className="HeroContainerMobile">
          <Link to="/Login" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }} className="lowerEmiMobile">
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={lowerEmiIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Lower Your EMIs
                </div>
              <Link to="/login"  style={{backgroundColor:'transparent'}}><FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
                </Link> 
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Use our algorithm to reduce your loan interest rate based on
                your credit score.{" "}
              </div>
            </div>
          </Link>
          <Link to="/login" className="lowerEmiMobile" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={ConsolidateIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                Club Multiple EMIs                </div>
                <Link to="/login" style={{backgroundColor:'transparent'}}>   <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
                </Link>
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Use our algorithm to reduce your loan interest rate based on
                your credit score.
              </div>
            </div>
          </Link>
          <Link className="lowerEmiMobile" to="/login" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "rgba(85, 50, 133, 0)" 
              }}
            >
              <img
                src={TopUPIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(85, 50, 133, 0)" 
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                Get New Loan with lower EMI
                </div>
                <Link to="/login"  style={{backgroundColor:'transparent'}}> <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
                </Link>
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Use our algorithm to reduce your loan interest rate based on
                your credit score.{" "}
              </div>
            </div>
          </Link>
          <Link className="lowerEmiMobile" to="https://minemi.ai/personal-loan/check-eligibility/"  target="_blank" onClick={() => { console.log("Link clicked"); window.scrollTo(0, 0); }}>
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={eligiblity}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  mixBlendMode:'multiply'
                }}
                alt=""
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                Discover Your Loan Eligibility
                </div>
                <Link to="/login"  style={{backgroundColor:'transparent'}}> <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
                </Link>
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Explore  your loan potential with no impact on your credit score
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
