import React, { useState, useRef } from "react";
import Vector from "../../Assets/Images/Vector.webp";
import "./Form.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
function Form() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loanAmount, oldIrr, tenure, newIrr, accountType, Account_Number } =
    location.state;
  const [outstanding, setoutStanding] = useState(loanAmount);
  const [oldIrrrs, seOldir] = useState(oldIrr);
  const [tenures, setTenure] = useState(tenure);
  const [newIrrs, setNewirrs] = useState(newIrr);
  const [salary, setSalary] = useState("");
  const [Address, setAddress] = useState("");
  const [workaddress, setWorkAddress] = useState("");
  const [image, setImage] = useState(null); // Initialize state with null instead of an empty string
  const [image1, setImage1] = useState(null); // Initialize state with null instead of an empty ....string
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const formData = new FormData();
  const id = localStorage.getItem("id");
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [errorMess, setErrorMess] = useState("");
  const [errorMess1, setErrorMess1] = useState("");
  const [errorMess2, setErrorMess2] = useState("");

  const HandleImageClick = () => {
    inputRef.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage(file.name);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
    // Get the first file from the list of selected files
  };
  const HandleImageClick1 = () => {
    inputRef1.current.click();
  };
  const handleChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImage1(file.name);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
    // Get the first file from the list of selected files
  };

  formData.append("goal", id);
  formData.append("account_Type", accountType);
  formData.append("uploadSalarySlip", image);
  formData.append("accountNumber", Account_Number);
  formData.append("permanentAddress", Address);
  formData.append("BankStatement", image1);
  formData.append("monthlyIncome", salary);
  formData.append("workAddress", workaddress);
  formData.append("outstandingLoanAmount", outstanding);
  formData.append("currentInterestRate", oldIrrrs);
  formData.append("remainingTenure", tenures);
  formData.append("newInterestRateOffered", newIrrs);
  const HandleUpdate = () => {
    if (
      outstanding === "" ||
      isNaN(outstanding) ||
      oldIrrrs === "" ||
      isNaN(oldIrrrs) ||
      tenures === "" ||
      isNaN(tenures) ||
      newIrrs === "" ||
      isNaN(newIrrs) ||
      salary === "" ||
      image == null ||
      image1 == null ||
      isNaN(salary) ||
      Address === "" ||
      !isNaN(Address) ||
      workaddress === "" ||
      !isNaN(workaddress)
    ) {
      setError(true);
    }
    if (salary === "" || isNaN(salary)) {
      setError(true);
      setErrorMess("Enter Valid Details");
    }
    if (Address === "" || !isNaN(Address)) {
      setErrorMess1("Enter Valid Details");
      setError1(true);
    }
    if (workaddress == "" || !isNaN(workaddress)) {
      setErrorMess2("Enter Valid Details");
      setError2(true);
    }
    if (image == null) {
      setImage(null);
    }
    if (image1 == null) {
      setImage1(null);
    } else {
      setError2(false);
      const token = localStorage.getItem("token");
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SECRET_KEY}/user/applyLoan`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };
      axios
        .request(options)
        .then(function (response) {
          // console.log(response.data);
          navigate("/confirm");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };
  const formatPrice = (price) => {
    if (!price) return ""; // Handle empty input
    
    // Remove existing commas and convert to string
    const cleanNumber = String(price).replace(/,/g, "");
    
    // Match only numbers
    if (!/^\d+$/.test(cleanNumber)) return "";
    
    // Convert to Indian format
    const lastThree = cleanNumber.substring(cleanNumber.length - 3);
    const otherNumbers = cleanNumber.substring(0, cleanNumber.length - 3);
    
    if (otherNumbers !== '') {
      // Add commas every two digits in the other numbers
      const formatted = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return formatted + "," + lastThree;
    }
    
    return lastThree;
  };
  return (
    <>
      <div
        className="form-heading"
        style={{
          display: "flex",
          marginTop: "60px",
          marginBottom: "60px",
          marginLeft: "90px",
          flexDirection: "column",
        }}
      >
        <div
          className="form-heading"
          style={{
            display: "flex",
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "35px",
            letterSpacing: "0em",
            color: "#59266D",
          }}
        >
          Fill Your Details
        </div>
        <div
          className="form-heading"
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#8A8A8A",
            marginTop: "10px",
          }}
        >
          Please provide the following details to confirm your{" "}
          <br className="breaker1" /> application
        </div>
      </div>
      <div
        className="main-profile-box"
        style={{
          display: "flex",
          gap: "24px",
          flexWrap: "wrap",
          marginLeft: "90px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Outstanding Loan Amount
          </p>
          <input
            className="Form-field"
            value={formatPrice(outstanding )}
            onChange={(e) => {
              // Filter out non-numeric characters
              const value = e.target.value.replace(/[^0-9]/g, "");

              setoutStanding(value);
            }}
            type="text"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {outstanding == "" || isNaN(outstanding) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Outstanding Loan Amount is required
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Current Interest Rate(Prefilled)
          </p>
          <input
            className="Form-field"
            value={oldIrrrs}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");

              seOldir(value);
            }}
            type="email"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {oldIrrrs == "" || isNaN(oldIrrrs) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Current Interest Rate is required
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Remaining Tenure(Prefilled)
          </p>
          <input
            className="Form-field"
            type="text"
            value={tenures}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");

              setTenure(value);
            }}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {tenures == "" || isNaN(tenures) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              Remaining Tenure is required
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            New Interest Rate offered
          </p>
          <input
            className="Form-field"
            type="email"
            value={newIrrs}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");

              setNewirrs(value);
            }}
            placeholder="Enter email"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {newIrrs == "" || isNaN(newIrrs) ? (
            <div style={{ color: "red", marginTop: "4px" }}>
              New Interest Rate is required
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div
        className="file-field"
        style={{
          display: "flex",
          marginTop: "40px",
          gap: "36px",
          marginBottom: "60px",
          flexWrap: "wrap",
          marginLeft: "90px",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Upload Salary Slips(s)
          </p>
          <div
            className="upload-box"
            style={{
              border: "1px dashed #8A8A8A",
              width: "378px",
              height: "104px",
              padding: "16px, 23px, 24px, 23px",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {image == null ? (
              <>
                <div style={{ backgroundColor: "transparent" }}>
                  <input
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleChange}
                    accept=".webp, .jpeg, .webp, .pdf" // Add this line to accept only jpg, jpeg, and png files
                  />
                  <img
                    onClick={HandleImageClick}
                    src={Vector}
                    alt=""
                    width="21px"
                    height="16px"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Upload Salary Slips(s)
                </div>
              </>
            ) : (
              <div style={{ backgroundColor: "transparent" }}>
                <div
                  style={{ display: "flex", backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>Name:</div>
                  <div style={{ backgroundColor: "transparent" }}>{image}</div>
                </div>
              </div>
            )}
          </div>
          {image == null ? (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Upload Your Salary Slip
            </div>
          ) : null}
          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#8A8A8A",
              marginTop: "10px",
            }}
          >
            Last 3 months salary slip <br />
            File Format:JPG,PNG,PDF
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Upload Bank Statements(s)
          </p>
          <div
            className="upload-box"
            style={{
              border: "1px dashed #8A8A8A",
              width: "378px",
              height: "104px",
              padding: "16px, 23px, 24px, 23px",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {image1 == null ? (
              <>
                <div style={{ backgroundColor: "transparent" }}>
                  <input
                    type="file"
                    ref={inputRef1}
                    style={{ display: "none" }}
                    onChange={handleChange1}
                    accept=".webp, .jpeg, .webp"
                  />
                  <img
                    onClick={HandleImageClick1}
                    src={Vector}
                    alt=""
                    width="21px"
                    height="16px"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "15px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    paddingTop: "10px",
                  }}
                >
                  Upload Bank Statement(s)
                </div>
              </>
            ) : (
              <div style={{ backgroundColor: "transparent" }}>
                <div
                  style={{ display: "flex", backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>Name:</div>
                  <div style={{ backgroundColor: "transparent" }}>{image1}</div>
                </div>
              </div>
            )}
          </div>
          {image1 == null ? (
            <div style={{ color: "red", marginTop: "5px" }}>
              Please Upload Your Bank Slip
            </div>
          ) : null}
          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#8A8A8A",
              marginTop: "10px",
            }}
          >
            Last 3 months bank statement <br />
            File Format:JPG,PNG,PDF
          </div>
        </div>
      </div>
      <div
        className="main-profile-box"
        style={{
          display: "flex",
          gap: "24px",
          marginLeft: "90px",
          marginBottom: "40px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Monthly Income/Salary
          </p>
          <input
            className="Form-field"
            type="text"
            placeholder="Monthly Income/Salary"
            value={formatPrice(salary)}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, "");

              setSalary(value);
            }}
            onBlur={() => {
              if (salary == "" || isNaN(salary)) {
                setError(true);
                setErrorMess("Please enter valid detail");
              } else {
                setError(false);
                setErrorMess("");
              }
            }}
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error && (
            <div style={{ color: "red", marginTop: "5px" }}>{errorMess}</div>
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Permanent Address
          </p>
          <input
            className="Form-field"
            onChange={(e) => setAddress(e.target.value)}
            onBlur={() => {
              if (Address == "" || !isNaN(Address)) {
                setError1(true);
                setErrorMess1("Please enter valid detail");
              } else {
                setError1(false);
                setErrorMess1("");
              }
            }}
            type="text"
            placeholder="Permanent Address"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error1 && (
            <div style={{ color: "red", marginTop: "5px" }}>{errorMess1}</div>
          )}
        </div>
        <div>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "rgba(31, 31, 31, 1)",
              paddingBottom: "10px",
            }}
          >
            Work Address
          </p>
          <input
            className="Form-field"
            type="text"
            onChange={(e) => setWorkAddress(e.target.value)}
            onBlur={() => {
              if (workaddress == "" || !isNaN(workaddress)) {
                setError2(true);
              } else {
                setError2(false);
                setErrorMess2("");
              }
            }}
            placeholder="Work Address"
            style={{
              width: "358px",
              height: "48px",
              paddingLeft: "20px",
              borderRadius: "8px",
              border: "1px",
              border: "1px solid rgba(138, 138, 138, 1)",
              color: "background: rgba(138, 138, 138, 1)",
              outline: "none",
              backgroundColor: "#FFFFFF",
            }}
          />
          {error2 && (
            <div style={{ color: "red", marginTop: "5px" }}>{errorMess2}</div>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "60px",
        }}
      >
        <button
          onClick={HandleUpdate}
          className="Form-fields"
          style={{
            width: "92%",
            height: "56px",
            borderRadius: "10px",
            gap: "8px",
            backgroundColor: "#59266D",
            cursor: "pointer",
            border: "none",
            outline: "none",
            color: "#FFFFFF",
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export default Form;
