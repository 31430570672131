import React, { useState } from 'react'
import img1 from "../../Assets/Images/image1.webp"
import img2 from "../../Assets/Images/Frame 2147225145.webp"
import img3 from "../../Assets/Images/Frame 2147225146.webp"
import img from "../../Assets/Images/image 48.webp";
import axis from "../../Assets/Images/axis.webp";
import sbi from "../../Assets/Images/sbi.webp";
import icici from "../../Assets/Images/icici.webp";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
const LoanTracking = () => {
  const banksData = [
    { name: "HDFC Bank", status: "Check Status", contact: "1800 22 1006", faq: "FAQs",link:'https://loanstatus.awd.world/hdfc-bank' },
    { name: "ICICI Bank", status: "Check Status", contact: "1800 200 3344", faq: "FAQs",link:'https://loanstatus.awd.world/icici-bank' },
    { name: "State Bank of India", status: "Check Status", contact: "1800 425 3800", faq: "FAQs",link:'https://loanstatus.awd.world/sbi-bank' },
    { name: "Axis Bank", status: "Check Status", contact: "1860 419 5555", faq: "FAQs" ,link:'https://loanstatus.awd.world/axis-bank'},
    { name: "Kotak Mahindra Bank", status: "Check Status", contact: "1860 266 2666", faq: "FAQs",link:'https://loanstatus.awd.world/kotak-bank' },
    { name: "Yes Bank", status: "Check Status", contact: "1800 1200", faq: "FAQs",link:'https://loanstatus.awd.world/yes-bank' },
    { name: "Punjab National Bank", status: "Check Status", contact: "1800 180 2222", faq: "FAQs",link:'https://loanstatus.awd.world/punjab-bank' },
    { name: "Bank of Baroda", status: "Check Status", contact: "1800 102 4455", faq: "FAQs" ,link:'https://loanstatus.awd.world/bob-bank'},
    { name: "IndusInd Bank", status: "Check Status", contact: "1860 500 5004", faq: "FAQs",link:'https://loanstatus.awd.world/indusind-bank' },
    { name: "Bajaj Finserv", status: "Check Status", contact: "1800 103 3535", faq: "FAQs" ,link:'https://loanstatus.awd.world/bajaj-bank'},
    { name: "Tata Capital", status: "Check Status", contact: "1860 267 6060", faq: "FAQs" ,link:'https://loanstatus.awd.world/tata-bank'},
    { name: "L&T Finance", status: "Check Status", contact: "1800 258 1800", faq: "FAQs",link:'https://loanstatus.awd.world/l&t-bank' },
    { name: "IDFC First Bank", status: "Check Status", contact: "1800 419 4332", faq: "FAQs" ,link:'https://loanstatus.awd.world/idfc-bank'},
    { name: "Canara Bank", status: "Check Status", contact: "	1800 425 0018", faq: "FAQs",link:'https://loanstatus.awd.world/canara-bank' },
    { name: "Union Bank of India", status: "Check Status", contact: "1800 208 2244", faq: "FAQs" ,link:'https://loanstatus.awd.world/union-bank'},
    { name: "Bank of India", status: "Check Status", contact: "1800 103 1906", faq: "FAQs" ,link:'https://loanstatus.awd.world/boi-bank'},
    { name: "IDBI Bank", status: "Check Status", contact: "	1800 209 4324", faq: "FAQs" ,link:'https://loanstatus.awd.world/idbi-bank'},
    { name: "Federal Bank", status: "Check Status", contact: "1800 420 1199", faq: "FAQs" ,link:'https://loanstatus.awd.world/fedral-bank'},
    { name: "RBL Bank", status: "Check Status", contact: "1800 123 8040", faq: "FAQs" ,link:'https://loanstatus.awd.world/rbl-bank'},
    { name: "HDFC Ltd.", status: "Check Status", contact: "1800 266 3345", faq: "FAQs" ,link:'https://loanstatus.awd.world/hdfcltd-bank'},
    { name: "LIC Housing Finance", status: "Check Status", contact: "1800 419 2121", faq: "FAQs" ,link:'https://loanstatus.awd.world/lic-finance'},
    { name: "ICICI Home Finance", status: "Check Status", contact: "1860 120 7777", faq: "FAQs" ,link:'https://loanstatus.awd.world/icici-finance'},
    { name: "PNB Housing Finance", status: "Check Status", contact: "1800 120 8800", faq: "FAQs",link:'https://loanstatus.awd.world/pnb-finance' },
    { name: "SBI Home Loans", status: "Check Status", contact: "1800 425 3800", faq: "FAQs" ,link:'https://loanstatus.awd.world/sbi-home-loan'},
    { name: "Aditya Birla Finance", status: "Check Status", contact: "1800 270 7000", faq: "FAQs" ,link:'https://loanstatus.awd.world/adityabirla-finance'},
  ];
  
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;
  
    // Pagination logic
    const totalPages = Math.ceil(banksData.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentRows = banksData.slice(startIndex, startIndex + rowsPerPage);
  
    const handlePrev = () => {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
  
    const handleNext = () => {
      if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };
  const navigate=useNavigate()
  return (
    <>
    <div
      style={{
        backgroundColor: "#E7E4F9",
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 40,
        paddingBottom: 40,
        overflow: "hidden",
        boxShadow: "2px 1px 2px 0px #00000040",
      }}
    >
      <div
        className="loanstatus-heading"
        style={{
          fontFamily: "Archivo",
          fontWeight: 700,
          fontSize: 40,
          color: "#59266D",
          backgroundColor: "#E7E4F9",
        }}
      >
       Track Your Loan Status Across Top Banks in India
      </div>
      <p
        className="loanstatus-paragraph"
        style={{
          fontFamily: "Archivo",
          fontWeight: 700,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1,
        }}
      >
Easily track the status of your loan application or existing loan across multiple banks using our loan status tracking tool. Stay informed on the progress of your loan at all times.    </p>   <p
        className="loanstatus-paragraph"
        style={{
          fontFamily: "Archivo",
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1,
        }}
      >
Whether you’re awaiting approval for a loan or monitoring the progress of your existing loan, keeping track of your loan status is crucial. Knowing where your loan stands allows you to plan better and ensure timely updates. Most banks in India offer convenient ways to check your loan status online, through their apps, or by visiting the nearest branch.      </p>
    </div>
    <div  className="container tracking-status" style={{marginLeft:'5%',marginRight:'5%',marginTop:64 }}>
        <p 
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
General Loan Status Tracking Process

</p>
        <p className='add-lineHeight'
          style={{
            fontFamily:'Archivo',
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            marginTop: 16,
          }}
        >
Many banks in India provide online loan status tracking through their customer portals or mobile apps. These tools allow you to enter your loan application reference number, registered mobile number, or other personal details to check the status of your loan in real time. Loan status updates typically include steps such as "Application Received," "Under Review," "Approved," and "Disbursed."      </p>
<p className='increase-margines'
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop:64
          }}
        >
How to Check Loan Status: General Steps
</p>
        <div className='center-image' style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap',gap:40,alignContent:'center'}}>
          <div style={{width:301,height:287}}>
            <img  src={img1} style={{width:'100%',height:'100%',objectFit:'contain'}} alt=""/>

          </div>
          <div style={{width:301,height:287}}>
            <img  src={img2} style={{width:'100%',height:'100%'}} alt=""/>

          </div>
          <div style={{width:301,height:287}}>
            <img  src={img3} style={{width:'100%',height:'100%'}} alt=""/>

          </div>
        </div>
        <p className='image-status-tracking'
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 24,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop:120
          }}
        >
Key Bank Loan Status Tracking

</p>
        <p className='below-tracking'
          style={{
            fontFamily:'Archivo',
            fontWeight: 400,
            fontSize: 16,
            color: "#1F1F1F",
            marginTop: 8,
          }}
        >
Below are the links to track your loan status with major banks in India. Select your bank to proceed:

</p>


<div  className="gap-box" style={{display:'flex',flexDirection:'column',gap:24,marginTop:'2%'}}>
  <div style={{display:'flex',flexDirection:'column',gap:2}}>
  <div style={{display:'flex',flexDirection:'column',gap:8}}>
  <div style={{display:'flex',flexDirection:'row',gap:2}}>
  <GoDotFill style={{alignSelf:'center'}} />

    <div style={{fontFamily:'Archivo',color:'#1F1F1F',fontSize:16,fontWeight:500}}>HDFC Bank Personal Loan Status</div>
  </div>
  <div style={{marginLeft:'1%',fontFamily:'Archivo',color:'#8A8A8A',fontSize:14,fontWeight:400}}>Track your HDFC Bank loan status by logging into their customer portal or using their mobile app.</div>
  </div>
  <div> 
     <Link style={{marginLeft:'1%',fontFamily:'Archivo',color:'#59266D',fontSize:14,fontWeight:400}} to="https://loanstatus.awd.world/hdfc-bank">Check HDFC Loan Status</Link>
  </div>

  </div>
  <div style={{display:'flex',flexDirection:'column',gap:2}}>
  <div style={{display:'flex',flexDirection:'column',gap:8}}>
  <div style={{display:'flex',flexDirection:'row',gap:2}}>
  <GoDotFill style={{alignSelf:'center'}} />

    <div style={{fontFamily:'Archivo',color:'#1F1F1F',fontSize:16,fontWeight:500}}>ICICI Bank Personal Loan Status</div>
  </div>
  <div style={{marginLeft:'1%',fontFamily:'Archivo',color:'#8A8A8A',fontSize:14,fontWeight:400}}>ICICI Bank offers real-time loan status tracking through its iMobile app or online customer portal.</div>
  </div>
  <div> 
     <Link style={{marginLeft:'1%',fontFamily:'Archivo',color:'#59266D',fontSize:14,fontWeight:400}} to="https://loanstatus.awd.world/icici-bank">Check ICICI Loan Status</Link>
  </div>

  </div>
  <div style={{display:'flex',flexDirection:'column',gap:2}}>
  <div style={{display:'flex',flexDirection:'column',gap:8}}>
  <div style={{display:'flex',flexDirection:'row',gap:2}}>
  <GoDotFill style={{alignSelf:'center'}} />

    <div style={{fontFamily:'Archivo',color:'#1F1F1F',fontSize:16,fontWeight:500}}>SBI Personal Loan Status</div>
  </div>
  <div style={{marginLeft:'1%',fontFamily:'Archivo',color:'#8A8A8A',fontSize:14,fontWeight:400}}>SBI allows you to track your loan application status through its official website or by visiting the nearest branch.</div>
  </div>
  <div> 
     <Link style={{marginLeft:'1%',fontFamily:'Archivo',color:'#59266D',fontSize:14,fontWeight:400}} to="https://loanstatus.awd.world/sbi-bank">Check SBI Loan Status</Link>
  </div>

  </div>
  <div style={{display:'flex',flexDirection:'column',gap:2}}>
  <div style={{display:'flex',flexDirection:'column',gap:8}}>
  <div style={{display:'flex',flexDirection:'row',gap:2}}>
  <GoDotFill style={{alignSelf:'center'}} />

    <div style={{fontFamily:'Archivo',color:'#1F1F1F',fontSize:16,fontWeight:500}} >Axis Bank Personal Loan Status</div>
  </div>
  <div style={{marginLeft:'1%',fontFamily:'Archivo',color:'#8A8A8A',fontSize:14,fontWeight:400}}>Axis Bank provides loan tracking services via its mobile app or through its online banking portal.</div>
  </div>
  <div> 
     <Link style={{marginLeft:'1%',fontFamily:'Archivo',color:'#59266D',fontSize:14,fontWeight:400}} to="https://loanstatus.awd.world/axis-bank">Check AXIS  Loan Status</Link>
  </div>

  </div>
</div>
<div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 64,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 12,
              marginLeft: "3%",
              paddingBottom: 0,
            }}
          >
            Top 25 Banks Loan Status Tracking
          </p>
{/* <div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display:'flex',
              flexDirection:'column',gap:8,
           
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>  
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
              
                </div>
              </div>
              <div  onClick={()=>navigate("/hdfc-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
             Kotak Mahindra Loan Status
              </div>
            </div>
<div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={axis}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                </div>
              </div>
              <div 
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              AXIS Loan  Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={sbi}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                </div>
              </div>
              <div 
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18

                }}
              >
              SBI Loan  Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF",borderBottom:'1px solid #D9D9D9' ,paddingBottom:15}}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={icici}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹50,00,000
                    </p>
                  </div>
                </div>
              </div>
              <div 
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              ICICI Loan  Eligibility
              </div>
            </div>
            <div style={{textAlign:'center',backgroundColor:'white',padding:5,fontFamily:'Archivo',fontWeight:600,color:'#1F1F1F',fontSize:18}}>Load More..</div>
              
      </div> */}
        <div className='banksname' style={{ padding: "20px", margin: "auto" ,borderRadius:24,marginTop:'1%'}}>
      <table style={tableStyles}>
        <thead>
          <tr>
            <th style={thStyles}>Bank/NBFC Name</th>
            <th style={thStyles}>Status Check Link</th>
            <th style={thStyles}>Customer Service Contact</th>
            <th style={thStyles}>FAQ/Help Link</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((bank, index) => (
            <tr key={index}>
              <td style={tdStyles}>{bank.name}</td>
              <td style={tdStylesStatus}  > <Link to={bank.link} style={{color:'#59266d'}}>{bank.status}</Link></td>
              <td style={tdStyles}>{bank.contact}</td>
              <td style={tdStyles}>{bank.faq}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='makebg' style={paginationStyles}>
        <button onClick={handlePrev} disabled={currentPage === 1} style={buttonStyles}>
          Prev
        </button>
        <span  className='makebg'>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNext} disabled={currentPage === totalPages} style={buttonStyles}>
          Next
        </button>
      </div>
    </div>
      </div>
      <div className='make-margin-80'
          style={{
            marginTop: 64,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Frequently Asked Questions (FAQs) </div>
    <div
          className="accordion "
          style={{
            marginTop: 24,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
          defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",}}
            >
            What do I need to check my loan status?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            To check your loan status, you typically need your loan application reference number, registered mobile number, or customer ID.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
How long does it take for my loan status to update?
</AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Loan status updates vary by bank, but most provide real-time updates as soon as there is progress in the approval or disbursement process.
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
Can I check the loan status offline?
</AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Yes, many banks allow you to check the status of your loan by visiting their branch or calling their customer service helpline.

            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div  className="increase-margines" style={{backgroundColor:'#E7E4F9',borderRadius:24,padding:40,marginTop:64,  marginBottom:149}}>
            <div style={{display:'flex',flexDirection:'column',gap:8,backgroundColor:'transparent'}}>
            <p className="minutes"style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:32,fontWeight:600}}>Check Your Eligibility in Minutes!</p>
            <p style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:18,fontWeight:400,lineHeight:1.2}}>Get personalized loan options based on your income, current EMI, and credit score. Quick, easy,<br className="display-none"/> and tailored just for you—apply now to see what you qualify for!</p>
            <div style={{backgroundColor:'transparent',marginTop:30,width:220}}>
                <Link className="buttom-loan-eligiblity-one" style={{backgroundColor:'#553285',color:'white',borderRadius:8,padding:10, paddingLeft:40,paddingRight:40,fontFamily:'Archivo',fontWeight:500,fontSize:16,textAlign:'center'}} to="https://loanstatus.awd.world/hdfc-bank">Check Status Now</Link>
            </div>
            </div>

        </div>
        </div>
    </>
  )
}

const tableStyles = {
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
};

const thStyles = {
  border: "1px solid #ddd",
  padding: "10px",
  textAlign: "left",
  backgroundColor: "#f4f4f4",
  fontWeight: "bold",
};

const tdStyles = {
  border: "1px solid #ddd",
  padding: "10px",
};
const tdStylesStatus = {
  border: "1px solid #ddd",
  padding: "10px",
  color:'#59266D',
  fontWeight:700,
  cursor:'pointer'
};

const paginationStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const buttonStyles = {
  padding: "10px 20px",
  backgroundColor: "#59266D",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export default LoanTracking