import React from 'react'
import ReactSpeedometer from "react-d3-speedometer";

function Test() {
  const score=500;
  return (
    <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'5%',marginLeft:'5%',marginRight:'5%'}}>
      <div style={{display:'flex',justifyContent:'space-between',padding:'3%',
      backgroundColor:'#FFFFFF',borderRadius:'10px',width:'100%',maxWidth:'600px',}}>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%',backgroundColor:'white'}}>
          <div style={{width:'180px',height:'120px',display:'flex',alignSelf:'center'}}>
            <ReactSpeedometer
              style={{ backgroundColor: "white"}}
              value={score}
              maxValue={900}
              minValue={0}
              customSegmentStops={[0, 500, 700, 800, 900]}
              segmentStopsFontSize="60px"
              width={180}
              height={120}
              needleColor="black"
              backgroundColor="white"
              color="white"
              segmentColors={[
                "rgba(255, 88, 88, 1)",
                " rgba(255, 159, 159, 1)",
                "rgba(255, 199, 2, 1)",
                "rgba(4, 218, 0, 1)",
                "rgba(2, 90, 0, 1)",
              ]}
              textColor="#59266D"
              ringWidth={30}
              needleHeightRatio={0.7}
              currentValueText={`${score}`}
            />
          </div>
          <div style={{alignSelf:'center',
        fontFamily: "Archivo",
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "16.8px",
        letterSpacing: "-0.02em",
        textAlign:'center',
        backgroundColor:'white',
        color: "rgba(70, 115, 110, 1)",
        }}>Your Credit score is <br/>
            760</div>
        </div>
      </div>
    </div>
  )
}

export default Test