import React, { useEffect, useState } from "react";
import HomeLogo from "../../Assets/Images/HomeLogo.webp";
import Detail from "../../Assets/Images/Detail.webp";
import "./Status.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
function Status() {
  const navigate = useNavigate();
  const location = useLocation();

  const { docId } = location.state;
  const [amount, setAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [interestrate, setInterestrate] = useState("");
  const [Monthlyemi, setMonthlyEmi] = useState("");
  const [bankLogo, setbankLogo] = useState("");
  const [value, setValue] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const DetailHandler = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/applicationFormDetail/${docId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setData(response.data.data.detail);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    DetailHandler();
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
      // console.log("user Login");
    }
  }, []);
  return (
    <>
      <div
        className="Application-detail"
        style={{ marginLeft: "120px", marginTop: "60px", marginBottom: "60px" }}
      >
        <div
          className="Application-page-heading"
          style={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "35px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#1F1F1F",
          }}
        >
          Application Details
        </div>

        <div
          className="Application-page-box1"
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "40px",
            gap: "24px",
          }}
        >
          <div
            className="boxLoan"
            style={{
              width: "358px",
              height: "288px",
              borderRadius: "10px",
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "50px",
                paddingBottom: "10px",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    width: "48px",
                    height: "48px",
                    borderRadius: "27px",
                    backgroundColor: "rgba(85, 50, 133, 0.1)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={HomeLogo}
                    alt=""
                    style={{
                      width: "32px",
                      height: "32px",
                      backgroundColor: "transparent",
                      position: "relative",
                      top: "7px",
                    }}
                  />
                </div>
                <div style={{ backgroundColor: "transparent" }}>
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "15px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "rgba(31, 31, 31, 1)",
                      paddingTop: "10px",
                      backgroundColor: "transparent",
                    }}
                  >
                    Home Loan
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineheight: "13px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "rgba(138, 138, 138, 1)",
                      paddingTop: "5px",
                      backgroundColor: "transparent",
                    }}
                  >
                    SBI
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                gap: "68px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " #1F1F1F",
                  paddingTop: "30px",
                  paddingLeft: "20px",
                }}
              >
                Loan Amount
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  paddingTop: "30px",
                  paddingRight: "20px",
                }}
              >
                ₹{data[0]?.loanamount}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                gap: "114px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " rgba(31, 31, 31, 1)",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                }}
              >
                Tenure
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  paddingTop: "20px",
                  paddingRight: "20px",
                }}
              >
                {data[0]?.remaining_tenure} Months
              </div>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                gap: "72px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " #1F1F1F",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                }}
              >
                Interest Rate
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  paddingTop: "20px",
                  paddingRight: "20px",
                  color: "#009964 ",
                }}
              >
                {data[0]?.interestRate}%
              </div>
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "transparent",
                gap: "74px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: " rgba(31, 31, 31, 1)",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                }}
              >
                Monthly EMI
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  paddingTop: "20px",
                  paddingRight: "20px",
                  color: "#009964 ",
                }}
              >
                ₹{data[0]?.currentemi}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "24px",
            }}
          >
            <div
              className="boxLoan"
              style={{
                width: "358px",
                height: "288px",
                borderRadius: "10px",
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "50px",
                  paddingBottom: "10px",
                  backgroundColor: "transparent",
                  paddingTop: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    backgroundColor: "transparent",
                  }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <div
                      style={{
                        fontSize: "21px",
                        fontWeight: "600",
                        lineHeight: "23px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(31, 31, 31, 1)",
                        paddingTop: "10px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {name}
                    </div>
                  </div>
                </div>
              </div>
              {data.map((d, index) => (
                <>
                  <div
                    className="Email-id"
                    style={{
                      display: "flex",
                      backgroundColor: "transparent",
                      gap: "110px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: " #1F1F1F",
                        paddingTop: "30px",
                        paddingLeft: "20px",
                      }}
                    >
                      Email-ID
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "#1F1F1F",
                        backgroundColor: "transparent",
                        paddingTop: "30px",
                        paddingRight: "20px",
                      }}
                    >
                      {d.email}
                    </div>
                  </div>
                  <div
                    className="Email-id2"
                    style={{
                      display: "flex",
                      backgroundColor: "transparent",
                      gap: "115px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: " rgba(31, 31, 31, 1)",
                        paddingTop: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      Contact
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "#1F1F1F",
                        backgroundColor: "transparent",
                        paddingTop: "20px",
                        paddingRight: "20px",
                      }}
                    >
                      {d.mobile}
                    </div>
                  </div>
                  <div
                    className="Email-id3"
                    style={{
                      display: "flex",
                      backgroundColor: "transparent",
                      gap: "117.2px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "17px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: " #1F1F1F",
                        paddingTop: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      Gender
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "#1F1F1F",
                        backgroundColor: "transparent",
                        paddingTop: "20px",
                        paddingRight: "20px",
                        color: "#1F1F1F",
                      }}
                    >
                      {d.gender == 0 ? "Male" : "Female"}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        {/* <div
          className="Application-page-2-heading"
          style={{
            fontSize: "32px",
            fontWeight: "700",
            lineHeight: "35px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#1F1F1F",
            marginTop: "40px",
          }}
        >
          {" "}
          Documents
        </div>
        <div className="Application-page-2" style={{ display: "flex" }}>
          <div
            className="img-box1"
            style={{
              marginTop: "20px",
              width: "370px",
              height: "104px",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              display: "flex",
              gap: "15px",
            }}
          >
            <div style={{ backgroundColor: "transparent" }}>
              <img
                src={Detail}
                alt=""
                width="118px"
                height="104px"
                style={{
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                alignSelf: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "17px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                }}
              >
                Income proof
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "13px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#59266D",
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                  textDecorationColor: "#59266D",
                  textUnderlineOffset: "0.2em",
                }}
              >
                View details
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Status;
