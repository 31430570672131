import React from 'react'
import { Accordion, Panel } from 'rsuite';
import "rsuite/dist/rsuite.css";
import { FaSortDown } from 'react-icons/fa';
import { LuDot } from "react-icons/lu";
import { Link } from 'react-router-dom';



const Faq = () => {
  return (
    <>
    <div className='container '>
        <p style={{textAlign:'center',fontSize:31,marginTop:'4%',fontWeight:'bold',fontFamily:'Archivo',lineHeight:1.4}}>Frequently Asked Questions</p>
<div style={{marginTop:50,marginLeft:'10%',marginRight:'10%'}}>
        <Accordion style={{display:'flex',flexDirection:'column',gap:24,backgroundColor:'transparent'}} >
  <Accordion.Panel   className="custom-accordion-panel" header="What is Minemi, and how does it work?" defaultExpanded style={{border:"0.5px solid grey",backgroundColor:'#F6F6F6',fontWeight:500,fontFamily:"Archivo",fontSize:16,borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    Minemi is India’s leading loan marketplace designed to help users compare and choose the best loans. With our tools, you can check loan eligibility, calculate EMIs, and compare interest rates across multiple banks—all without impacting your credit score.
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel"  header="How can I check my loan eligibility without impacting my credit score?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    You can use Minemi’s loan eligibility checker. It provides personalized insights based on your income, credit score, and financial details. The tool uses a soft inquiry, ensuring your credit score remains unaffected.<br/>
  Learn More:  <span style={{color:'#553285',fontWeight:'bold'}}>Check Loan Eligibility</span>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="What types of loans does Minemi offer?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    Minemi helps users with multiple loan categories, including:<br/>

1) Personal Loans: For weddings, travel, medical emergencies, and more.<br/>

2)Home Loans: For property purchase, renovation, or top-ups.<br/>

3)Car Loans: For both new and used vehicles.<br/>
Learn More:<Link to="/loanpage" style={{color:'#553285',fontWeight:'bold'}}>Explore Loan Types</Link> 
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="What is a balance transfer, and how does it work?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    A balance transfer lets you shift your existing loan to another lender offering lower interest rates or better terms. This helps reduce EMIs and save on overall interest payments.<br/>
    Learn More:<Link to="/balancetransfer" style={{color:'#553285',fontWeight:'bold'}}> Balance Transfer Details</Link>
  </Accordion.Panel>
  <Accordion.Panel  className="custom-accordion-panel" header="Are personal loans suitable for weddings or travel expenses?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}></p>Yes, personal loans can cover major expenses like wedding costs, honeymoon trips, or travel bookings. However, it’s advisable to opt for shorter repayment tenures and only borrow what you can comfortably repay.<br/>
    Learn More:<Link style={{color:'#553285',fontWeight:'bold'}}>Wedding Loans Guide</Link>
  </Accordion.Panel> 
   <Accordion.Panel  className="custom-accordion-panel" header="How do I compare loan interest rates using Minemi?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}></p>Minemi’s comparison tools help you analyze interest rates, processing fees, and repayment terms across lenders. This ensures you find the most cost-effective loan for your needs.<br/>
    Learn More: <Link  style={{color:'#553285',fontWeight:'bold'}}>Compare Interest Rates</Link> <br/>
    Learn More:<Link  style={{color:'#553285',fontWeight:'bold'}}>Wedding Loans Guide</Link>
  </Accordion.Panel>
   <Accordion.Panel  className="custom-accordion-panel" header="How do I improve my chances of getting a loan approved?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}></p>To increase your loan approval chances:<br/>

1. Maintain a high credit score (above 700).<br/>


2. Clear outstanding debts and lower your FOIR (Fixed Obligation to Income Ratio).<br/>


3. Opt for a co-applicant with a strong financial profile if needed.<br/>
Learn More:<Link  style={{color:'#553285',fontWeight:'bold'}}>Loan Approval Tips</Link> <br/>
    Learn More:<Link  style={{color:'#553285',fontWeight:'bold'}}>Compare Interest Rates</Link> <br/>
    Learn More:<Link  style={{color:'#553285',fontWeight:'bold'}}> Wedding Loans Guide</Link><br/>
  </Accordion.Panel> 
  <Accordion.Panel  className="custom-accordion-panel" header="What documents are required to apply for a loan?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}></p>The required documents include:<br/>
    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Proof of Identity (Aadhaar, PAN, Passport)</div><br/>
    </div>

    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Address Proof (Utility Bills, Rent Agreement, Aadhaar)</div><br/>
    </div> 
     <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Income Proof (Salary Slips, Bank Statements)</div><br/>
    </div>
      <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Employment Proof (Offer Letter, Work ID)</div><br/>
    </div>
<div>Learn More: <Link style={{color:'#553285',fontWeight:'bold'}}>Loan Documentation Checklist</Link> </div>





  </Accordion.Panel> 
  <Accordion.Panel  className="custom-accordion-panel" header=" How long does it take to get a loan approved through Minemi?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <p style={{fontWeight:500,fontFamily:"Archivo",fontSize:16}}></p>
Approval timelines vary by lender but generally range between 24 and 72 hours for personal and car loans. Home loans may take longer due to property verification.<br/>
Learn More: <Link style={{color:'#553285',fontWeight:'bold'}}>Loan Processing Times</Link>
  </Accordion.Panel> 
  <Accordion.Panel  className="custom-accordion-panel" header=" How can I avoid loan fraud while applying online?" style={{border:"0.5px solid grey",backgroundColor:'transparent',borderTop:0,borderLeft:0,borderRight:0,borderRadius:0}} caretAs={FaSortDown}>
    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>To protect yourself from loan fraud: </div><br/>
    </div>
    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Verify the lender’s RBI registration.</div><br/>
    </div>
    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Avoid sharing sensitive data like phone contacts or OTPs.</div><br/>
    </div>

    <div style={{display:'flex',flexDirection:'row'}}>
    <LuDot style={{alignSelf:'center'}} size={20} /> <div>Read the terms and conditions thoroughly.</div><br/>
    </div>
<div style={{paddingLeft:'1%'}}>Learn More: <Link style={{color:'#553285',fontWeight:'bold'}}>Loan Fraud Prevention Tips</Link><br/></div>

  </Accordion.Panel>
</Accordion>
</div>

    </div>
{/* <div className='container-fluid ' style={{backgroundColor:"#dfc6ef",marginTop:'4%',display:'flex',justifyContent:'space-between',padding:'14%',paddingTop:'5%',paddingBottom:'5%',flexWrap:'wrap'}}>
    <div style={{display:'flex',flexDirection:'column',gap:16,backgroundColor:'transparent'}} >
    <div style={{backgroundColor:"#dfc6ef",fontFamily:'Archivo',fontWeight:'bold',fontSize:32}}>Grow your business<br/> today</div>
    <div style={{backgroundColor:"#dfc6ef",fontFamily:'Archivo',fontWeight:400,fontSize:15}}>Try it free. No credit card required. Instant setup.</div>
    </div>
    <div style={{display:'flex',flexDirection:'row',gap:8,backgroundColor:'transparent',alignSelf:'center'}}>
    <input className='main-input-filled' type="text" id="fname" name="fname" style={{backgroundColor:'transparent',width:358,height:40,paddingLeft:8,outline:"none"}}/>
    <div className='main-input-button' style={{backgroundColor:'#553285',fontFamily:'Archivo',fontWeight:600,fontSize:19,height:44,alignContent:'center',width:200,textAlign:'center',color:'white',borderRadius:4}}>Submit</div>

    </div>
</div> */}
</>
  )
}

export default Faq