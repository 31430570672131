import React from 'react'
import image from "../../Assets/Images/image 48.webp"
import { LuDot } from 'react-icons/lu'
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';


const HdfcEligiblity = () => {
  const criteriaData = [
    {
      factor: "Income",
      requirement: "₹25,000/month (salaried individuals)",
      impact: "Higher income increases loan eligibility and the amount you can borrow.",
    },
    {
      factor: "Credit Score",
      requirement: "700+ (recommended)",
      impact: "A higher score may result in better interest rates and loan terms.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried individuals with a minimum of 1 year at the current job",
      impact: "Stable employment enhances approval chances.",
    },
    {
      factor: "Age",
      requirement: "21 to 60 years",
      impact: "Applicants within this age range are eligible.",
    },
    {
      factor: "Loan Amount",
      requirement: "Up to ₹40,00,000",
      impact: "Based on eligibility factors, higher amounts are available for applicants with strong financial profiles.",
    },
      {
      factor: "Tenure",
      requirement: "1 to 5 years",
      impact: "Flexible tenure options available.",
    },
  ];

  return (
    <>
    <div
      style={{
        backgroundColor: "#E7E4F9",
        paddingLeft: "5%",
        paddingRight: "5%",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingTop: 40,
        paddingBottom: 40,
        overflow: "hidden",
        boxShadow: "2px 1px 2px 0px #00000040",
      }}
    >
      <div
        className="homeloan-heading"
        style={{
          fontFamily: "Archivo",
          fontWeight: 700,
          fontSize: 40,
          color: "#59266D",
          backgroundColor: "#E7E4F9",
        }}
      >
        {" "}
        Check Your Personal Loan Eligibility Instantly with HDFC Bank
      </div>
      <p
        className="homeloan-paragraph"
        style={{
          fontFamily: "Archivo",
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1,
        }}
      >
Discover how much you can borrow from HDFC Bank without impacting your credit score. Use our eligibility calculator and get personalized insights based on your income, credit score, and other factors.      </p>  
     
    </div>
    <div  className="container" style={{marginLeft:'5%',marginRight:'5%',marginTop: 30 }}>
        <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Personal Loan Eligibility Criteria
        </p>
        {/* <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 400,
            fontSize: 16,
            color: "#8A8A8A",
            marginTop: 8,
          }}
        >
         Lorem ipsum dolor sit amet consectetur. Cras diam blandit morbi maecenas laoreet lorem scelerisque enim morbi.
        </p> */}
        <div
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: 24,
        marginTop: 24,
        backgroundColor: "white",
        marginBottom: "2%",
        paddingBottom: 24,
        maxWidth: "100%",
      }}
    >
      <div style={{ backgroundColor: "white", borderTopLeftRadius: 24, borderTopRightRadius: 24 }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 16,
            color: "#59266D",
            padding: "16px",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            textAlign: "center",
          }}
        >
          HDFC Loan Eligibility Criteria
        </div>

        <div
          style={{
            backgroundColor: "white",
            borderRadius: 24,
            paddingTop: 24,
            paddingBottom: 24,
            display: "flex",
            paddingLeft:'1%'
          }}
        >
          <div style={{ width: 141, height: 62 }}>
            <img src={image} alt="HDFC Logo" style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      </div>

      <div style={{ padding: "0 1.5%", maxWidth: "100%", overflowX: "auto" ,backgroundColor:'white'}}>
        <table className='table-class'
          style={{
            fontFamily: "Archivo",
            borderCollapse: "collapse",
            borderRadius: 12,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "left",
          }}
        >
          <thead className='thead-class'>
            <tr>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Eligibility Factor
              </th>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Minimum Requirement
              </th>
              <th className='th-class' style={{ padding: "16px", backgroundColor: "#E7E4F9", color: "#59266D" }}>
                Impact on Loan Eligibility
              </th>
            </tr>
          </thead>
          <tbody>
            {criteriaData.map((row, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white" }}>
                <td  className='td-class'style={{ padding: "16px", fontWeight: 700 }}>{row.factor}</td>
                <td className='td-class' style={{ padding: "16px" }}>{row.requirement}</td>
                <td className='td-class' style={{ padding: "16px" }}>{row.impact}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Understanding Your Eligibility for HDFC Bank Personal Loans</div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
HDFC Bank evaluates your personal loan eligibility based on multiple factors, ensuring a seamless and transparent process:</div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Income:</span>
          A higher monthly income allows you to borrow more.      </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Credit Score:</span>
          A score above 700 improves your chances of approval and may result in better interest rates. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Employment Status:</span>
          Salaried employees with stable job history have higher approval chances.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Age:</span>
          Applicants aged 21-60 years qualify, with flexible terms based on repayment capacity. </div>
            </div>
    
        </div>
        {/* <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Documents Required for HDFC Bank Personal Loan   </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
Gather the required documents to ensure a smooth application process. Here’s what you’ll need to apply for an HDFC Bank personal loan.</div>

<div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>  Identity Proof: </span>
         Aadhaar card, PAN card, passport, voter ID, or driving license.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Address Proof:</span>
           Utility bills, rental agreement, or passport </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <div style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Income Proof:</div>
           <div><span style={{paddingLeft:'1%',color:'black' ,color: "#1F1F1F", fontWeight: 500, fontSize: 15 }}>• Salaried:</span> Latest 3 months’ salary slips and bank statements for the last 6 months.</div>
            <div style={{paddingLeft:'1%'}}><span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 15,marginTop:5}}>• Self-Employed:</span> Income tax returns for the last 2 years and audited financials.</div></div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Employment/Business Stability: </span>
         Minimum 2 years of work experience (salaried) with 1 year in the current job; 3 years in current business for self-employed. </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Photographs:</span>
           Recent passport-sized photos.</div>
            </div>
        </div>



 */}
 
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Improve Your Eligibility for HDFC Bank Personal Loans

</div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
If you don’t qualify for an HDFC Bank loan, consider these tips:

</div>

<div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Boost Your Credit Score:</span>
          Pay off existing debts and maintain a clean repayment history.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Consolidate Loans:</span>
          Reduce financial obligations to improve your FOIR.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Add a Co-Applicant:</span>
          A co-applicant with a higher income or better credit score can enhance eligibility.</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Opt for Longer Tenure:</span>
          Lower your EMIs to qualify for higher loan amounts.</div>
            </div>
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Types of Loans and Their Eligibility  </div>
        <div  className="increase-margin" style={{marginTop:'2%',border:'1px solid #846CA6',borderRadius:18,}}>
      <table
      id="customers"
      style={{
        fontFamily: 'Archivo',
        borderCollapse: 'collapse',
        borderRadius:18,
        width:'100%'
        
      }}
    >
      <thead style={{borderRadius:18}} > 
        <tr style={{borderRadius:18}}   >
          <th className="table-padding"
            style={{
              
                 backgroundColor: '#E7E4F9',backgroundColor: '#E7E4F9',
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              borderTopLeftRadius:18,
              color: '#59266D',
             
            }}
          >
        Type of Loan
          </th>
          <th className="table-padding"
            style={{
             borderLeft:'1px solid #D9D9D9',
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              backgroundColor: '#E7E4F9',
              color: '#59266D',
            }}
          >
      Applicability
          </th>
          <th className="table-padding"
            style={{
                borderLeft:'1px solid #D9D9D9',
                borderTopRightRadius:18,
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              backgroundColor: '#E7E4F9',
              color: '#59266D',
            }}
          >       	
Eligibility Criteria
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Wedding Loan</td>
          <td   className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>     	
Covers wedding expenses, including venue, catering, and decorations.</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}> Salaried individuals with a minimum income of ₹25,000/month and a credit score of 700+.</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Travel Loan</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	Funds domestic or international travel expenses.

</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          Salaried individuals with a stable job history and a credit score of 700+.</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Debt Consolidation Loan</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Helps consolidate multiple high-interest loans into a single loan.</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Salaried individuals with a FOIR of less than 50% and a credit score of 700+.</td>
        </tr>
       
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td  className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Top-Up Loan



</td>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          Additional funds on an existing HDFC personal loan for unforeseen expenses.

</td>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Existing HDFC personal loan customers with a good repayment history.

</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 ,borderBottomRightRadius:24}}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700,border: '1px solid #ddd',borderBottomLeftRadius:18  }}>Emergency Loan

</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Addresses urgent medical or other financial emergencies.	

</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,border: '1px solid #ddd',borderBottomRightRadius:18}}>	
          Salaried individuals with valid proof of emergency and a credit score of 650+.</td>
        </tr> 
         <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 }}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 ,borderBottomLeftRadius:24 }}>Business Expansion Loan



</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          Funds small-scale business or entrepreneurial ventures.
</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,borderBottomRightRadius:24  }}>	
          Salaried professionals or self-employed individuals with a minimum annual income of ₹3,00,000 and a credit score of 750+.</td>
        </tr>
      </tbody>
    </table>

      </div>
        
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Frequently Asked Questions (FAQs) on HDFC Bank Personal Loan Eligibility  </div>
    <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
          defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",}}
            >
              Accordion 1
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
          <Accordion
            style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
            >
              Accordion Actions
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div  className="increase-margines" style={{backgroundColor:'#E7E4F9',borderRadius:24,padding:40,marginTop:'2%',marginBottom:'4%'}}>
            <div style={{display:'flex',flexDirection:'column',gap:8,backgroundColor:'transparent'}}>
            <p className="minutes"style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:32,fontWeight:600}}>Check Your Eligibility in Minutes!</p>
            <p style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:18,fontWeight:400,lineHeight:1.2}}>Get personalized loan options based on your income, current EMI, and credit score. Quick, easy,<br/> and tailored just for you—apply now to see what you qualify for!</p>
            <Link to="https://minemi.ai/personal-loan/check-eligibility"  style={{display:'flex',flexDirection:'row',gap:24,backgroundColor:'transparent',marginTop:'2%',cursor:'pointer'}}>
                <div className="buttom-loan-eligiblity-one" style={{backgroundColor:'#553285',color:'white',borderRadius:8,padding:10, paddingLeft:40,paddingRight:40,fontFamily:'Archivo',fontWeight:500,fontSize:18}} > Check Your Eligibility Now</div>

            </Link>
            </div>

        </div>
    </div>
    

    </>
  )
}

export default HdfcEligiblity