import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./HeroSection.css";


function ExperianConsent() {
  const [htmlContent, setHtmlContent] = useState('');

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.experian_consent);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);

  return (
    <>
      <div style={{marginLeft:'5%',marginRight:'5%', marginTop: 70, fontSize: '24px', lineHeight: '2.05rem', color: 'rgb(89, 38, 109)', fontWeight: 'bold',marginBottom:'2%' }}>
        Experian's Terms and Condition
      </div>
      <div
        className="api-text"
        style={{ marginLeft: "5%", marginRight: "5%" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />    </>
  );
}

export default ExperianConsent;
