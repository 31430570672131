import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function GrievanceAddressal() {
  const [htmlContent, setHtmlContent] = useState("");

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.grievance_addressal_text);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <>
      <Helmet>
        <title>App Grievance Redressal for Users | Minemi</title>
        <meta
          name="description"
          content="Contact our grievance redressal team for quick support in resolving app-related issues and queries."
        />
      </Helmet>
      <div
        className="api-text"
        style={{ marginLeft: "5%", marginRight: "5%" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}

export default GrievanceAddressal;
