import React, { useState } from "react";
import illustration from "../../Assets/Images/Illustration22.webp"
import img from "../../Assets/Images/image 48.webp";
import axis from "../../Assets/Images/axis.webp";
import sbi from "../../Assets/Images/sbi.webp";
import icici from "../../Assets/Images/icici.webp";
import { LuDot } from "react-icons/lu";
import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from "@mui/material";
import './EligiblityCriteria.css'
import { Navigate, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";


const EligiblityCriteria = () => {
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState(0);
  const [foir, setFOIR] = useState(0);
  const  navigate=useNavigate()

  const calculateLoanEligibility = () => {
    // Parse inputs
    const income = parseFloat(monthlyIncome);
    const obligations = parseFloat(existingObligations);
    const tenure = parseInt(loanTenure, 10);
    const interestRate = parseFloat(annualInterestRate);
  
    if (!income || !obligations || !tenure || !interestRate) {
      alert("Please fill in all fields with valid numbers.");
      return;
    }
  
    // Calculate FOIR
    let calculatedFOIR = obligations / income; // Use `let` if the value might change
    setFOIR(calculatedFOIR);
  
    if (calculatedFOIR >= 0.5) {
      alert("Loan eligibility is low due to high FOIR.");
      setEligibility(0);
      return;
    }
  
    // Calculate Net Income Available for EMI
    const netIncomeAvailable = income - obligations;
  
    // Maximum EMI Allowed
    const maxEMI = income * (1 - calculatedFOIR);
  
    // Monthly Interest Rate
    const monthlyInterestRate = interestRate / 12 / 100;
  
    // Loan Principal Eligibility
    const calculatedEligibility =
      (maxEMI * (Math.pow(1 + monthlyInterestRate, tenure) - 1)) /
      (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, tenure));
  
    setEligibility(calculatedEligibility);
  };
  
   return (
    <>
      <div className="margin-less"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "5%",
          paddingRight: "5%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        <div
                 className="loanstatus-heading"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Check Your Personal Loan Eligibility Instantly

</div>
        <p
                className="loanstatus-paragraph"

          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
Find out how much you can borrow from top lenders without impacting your credit score. Use our personal loan eligibility calculator and get personalized insights based on your income, credit score, and other factors.        </p>  
         {/* <p
                className="loanstatus-paragraph"

          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
       Whether you’re awaiting approval for a loan or monitoring the progress of your existing loan, keeping track of your loan status is crucial. Knowing where your loan stands allows you to plan better and ensure timely updates. Most banks in India offer convenient ways to check your loan status online, through their apps, or by visiting the nearest branch.
        </p> */}
      </div>
      <div  className="container  tracking-status" style={{marginLeft:'5%',marginRight:'5%',marginTop: 56 }}>
        <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Personal Loan Eligibility Criteria
        </p>
        {/* <p
          style={{
            fontFamily:'Archivo',
            fontWeight: 400,
            fontSize: 16,
            color: "#8A8A8A",
            marginTop: 8,
          }}
        >
        </p> */}
      
      <div  className="increase-margin" style={{marginTop:'2%',border:'1px solid #846CA6',borderRadius:18,}}>
      <table
      id="customers"
      style={{
        fontFamily: 'Archivo',
        borderCollapse: 'collapse',
        borderRadius:18,
        width:'100%'
        
      }}
    >
      <thead style={{borderRadius:18}} > 
        <tr style={{borderRadius:18}}   >
          <th className="table-padding"
            style={{
              
                 backgroundColor: '#E7E4F9',backgroundColor: '#E7E4F9',
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              borderTopLeftRadius:18,
              color: '#59266D',
             
            }}
          >
           Eligibility Factor
          </th>
          <th className="table-padding"
            style={{
             borderLeft:'1px solid #D9D9D9',
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              backgroundColor: '#E7E4F9',
              color: '#59266D',
            }}
          >
         Minimum Requirement
          </th>
          <th className="table-padding"
            style={{
                borderLeft:'1px solid #D9D9D9',
                borderTopRightRadius:18,
              paddingTop: '16px',
              paddingBottom: '16px',
              paddingLeft: '6px',
              textAlign: 'left',
              backgroundColor: '#E7E4F9',
              color: '#59266D',
            }}
          >
          Impact on Loan Eligibility
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Income</td>
          <td   className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          ₹25,000/month (salaried individuals)</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Higher income increases eligibility and the amount you can borrow.</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Credit Score</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>700+ (recommended)

</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          A higher score may result in better interest rates and loan terms.</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Employment Status</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Salaried individuals with a minimum of 1 year at the current job</td>
          <td  className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Stable employment enhances approval chances.</td>
        </tr>
       
        <tr style={{ backgroundColor: '#f2f2f2' }}>
          <td  className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 }}>Age

</td>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>21 to 60 years

</td>
          <td className="table-padding" style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>Applicants within this age range are eligible.

</td>
        </tr>
        <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 ,borderBottomRightRadius:24}}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700,border: '1px solid #ddd',borderBottomLeftRadius:18  }}>Fixed Obligations to Income Ratio (FOIR)

</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>40%-50%	

</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,border: '1px solid #ddd',borderBottomRightRadius:18}}>	
          A lower FOIR indicates fewer existing EMIs and improves loan eligibility.</td>
        </tr> 
         {/* <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 }}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 ,borderBottomLeftRadius:24 }}>Tenure



</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          1 to 5 years

</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,borderBottomRightRadius:24  }}>	
	
          Flexible tenure options available.</td>
        </tr> */}
      </tbody>
    </table>

      </div>


      
   
      <div className="make-it-center increase-margin " style={{marginTop:'2%', marginBottom:'2%', padding:24,   background: 'linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)',display:'flex',justifyContent:'space-between',flexWrap:'wrap',gap:20,borderRadius:24
}}>

    <div style={{marginLeft:'4%',backgroundColor:'transparent',display:'flex',flexDirection:'column',gap:24,borderRadius:24,alignSelf:'center'}}>
        <p className="eligiblity-criteria" style={{fontFamily:'Archivo',color:'#59266D',fontSize:40,backgroundColor:'transparent'}}><i style={{backgroundColor:'transparent',lineHeight:1.2}}>Check Your Loan Eligibility Without <br className="brake-remove"/> Impacting Your Credit Score</i></p>
        <Link  to="https://minemi.ai/personal-loan/check-eligibility/" className="eligiblity-buttons" style={{backgroundColor:'#553285',color:'#FFFFFF', width:'39%',borderRadius:8,fontFamily:'Archivo',fontWeight:500,fontSize:18,paddingTop:8,paddingBottom:8,textAlign:'center'}}>Check  Eligibility</Link>
    </div>
    <div  style={{width:200,height:195,backgroundColor:'transparent',marginRight:'4%',display:'flex', flexDirection:'row',alignContent:'center'}}>
        <img src={illustration} style={{width:'100%',height:'100%',backgroundColor:'transparent'}} alt=""/>
    </div>
</div>

<p
          style={{
            fontFamily:'Archivo',
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
            marginTop:'3%'
          }}
        >
Eligibility Criteria by Lender
</p>

   
<div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 24,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 14,
              marginLeft: "1%",
              paddingBottom: 0,
            }}
          >
           Eligibility Criteria by Lender
          </p>
<div
            style={{
              marginTop: 16,
              backgroundColor: "#FFFFFF",
              padding: 16,
              borderRadius: 12,
              display:'flex',
              flexDirection:'column',gap:8,
           
            }}
          >
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={img}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
              
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Minimum Credit Score	
                     
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹25,000/month	
                    </p>
                  </div>  
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                    Maximum Loan Tenure	
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >	Up to 5 years
                    </p>
                  </div>
              
                </div>
              </div>
              <div  onClick={()=>navigate("/hdfc-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              HDFC Loan  Eligibility
              </div>
            </div>
<div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={axis}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Minimum Credit Score	 
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >720
                    </p>
                  </div>
              
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
Income Requirement	
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹20,000/month	
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 7 years	
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("/axis-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256, fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              AXIS Loan  Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={sbi}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
             
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
Minimum Credit Score

</p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >700
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                    ₹25,000/month	
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >up to 5 years
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("/sbi-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18

                }}
              >
              SBI Loan  Eligibility
              </div>
            </div>
            <div className="main-box" style={{ backgroundColor: "#FFFFFF",borderBottom:'1px solid #D9D9D9' ,paddingBottom:15}}>
              <div className="gap-1" style={{ backgroundColor: "#FFFFFF" }}>
                <div
                  className="bank-image"
                  style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
                >
                  <img
                    src={icici}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
                
                <div className="gap-2" style={{ backgroundColor: "#FFFFFF" }}>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Minimum Credit Score	
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >680		
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                      Income Requirement
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >₹25,000/month	
                    </p>
                  </div>
                  <div
                    className="headingss"
                    style={{
                      display: "flex",
                      flexDirection: "column",
              
                      backgroundColor: "#FFFFFF",
                      justifyContent:"center",

                    }}
                  >
                    <p
                      className="main-h"
                      style={{ backgroundColor: "#FFFFFF",color:'#8A8A8A',alignSelf:'center',  }}
                    >
                     Maximum Loan Tenure
                    </p>
                    <p
                      className="main-sub"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >Up to 6 years		
                    </p>
                  </div>
                </div>
              </div>
              <div  onClick={()=>navigate("/icici-loan-eligiblity")}
                className="view-detail"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width:256,
                  fontFamily:'Archivo',color:'#FFFFFF',fontWeight:500,fontSize:18
                }}
              >
              ICICI Loan  Eligibility
              </div>
            </div>
            <div style={{textAlign:'center',backgroundColor:'white',padding:5,fontFamily:'Archivo',fontWeight:600,color:'#1F1F1F',fontSize:18}}>Load More..</div>
              
      </div>
      </div>
      <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
 Understanding Your Eligibility for a Personal Loan        </div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
Personal loan eligibility is determined by a range of factors, including your income, credit score, existing debt, and employment status. Lenders assess these criteria to ensure you can repay the loan comfortably without over-leveraging yourself. By checking your eligibility beforehand, you can avoid the risk of rejection and increase your chances of securing the loan amount you need.        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Income:</span>
          Lenders typically require a minimum monthly income of ₹25,000 for salaried individuals. The higher your income, the more likely you are to qualify for a larger loan.          </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Credit Score:</span>
          Your credit score is a key factor in determining loan approval and interest rates. A score above 700 generally leads to faster approval and more favorable loan terms.      </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}> Fixed Obligations to Income Ratio (FOIR): </span>
          This ratio compares your fixed monthly obligations (existing EMIs, rent, etc.) to your income. A lower FOIR means fewer financial commitments, making you more eligible for a higher loan amount.  </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
          
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Employment Status:</span>
           Both salaried and self-employed individuals can apply for personal loans. However, salaried applicants generally have an easier time qualifying, while self-employed individuals may need to provide more documentation proving business stability.    </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:4}}>
                <div style={{display:'flex',flexDirection:'row'}}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
            <LuDot  />
            </span>
                </div>
          <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
          <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>Age: </span>
         This ratio compares your fixed monthly obligations (existing EMIs, rent, etc.) to your income. A lower FOIR means fewer financial commitments, making you more eligible for a higher loan amount.      </div>
            </div>
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Use Our Eligibility Calculator to Get Personalized Results
</div>
        <div   style={{
            fontFamily:'Archivo',
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}>
Using our personal loan eligibility calculator, you can instantly assess how much you can borrow based on your financial profile. The calculator considers your income, current EMIs, credit score, and more to give you a personalized result. This helps you understand your borrowing capacity before submitting an official loan application.     </div>

<div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Use Our Eligibility Calculator to Get Personalized Results
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
        If you don’t meet the eligibility criteria or want to increase your chances of approval, here are some tips:
        </div>
        <div  className="main-inner-box-eligiblity increase-margin " style={{ marginTop:'2%', 
            backgroundColor:'white',borderRadius:24,border:'2px solid #846CA6',borderRight:0}}>
        <div
          className="container loan-criteria-box "
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent:'space-between',
            flexWrap:'wrap',
           backgroundColor:'white',borderRadius:24
          
           
          }}
        >
          <div style={{display:'flex',flexDirection:'column',gap:24,backgroundColor:'white', margin:32}}>
       <div className="input-fiedl" style={{width:358,border:'1px solid #846CA6',borderRadius:8,backgroundColor:'#FFFFFF',height:48}}>
        <input  className="input-fiedl-inner" placeholder="₹..." style={{width:350,border:"0px solid white",marginLeft:5, marginRight:8,outline:'none',marginTop:4,  backgroundColor:'white'}} onChange={(e)=>setMonthlyIncome(e.target.value)}/>
       </div>
       <div className="input-fiedl" style={{width:358,border:'1px solid #846CA6',borderRadius:8,backgroundColor:'#FFFFFF',height:48}}>
        <input  className="input-fiedl-inner" placeholder="₹..." style={{width:350,border:"0px solid white",marginLeft:5, marginRight:8,outline:'none',marginTop:4,  backgroundColor:'white'}} onChange={(e)=>setExistingObligations(e.target.value)}/>
       </div>
       <div className="input-fiedl" style={{width:358,border:'1px solid #846CA6',borderRadius:8,backgroundColor:'#FFFFFF',height:48}}>
        <input  className="input-fiedl-inner" placeholder="₹..." style={{width:350,border:"0px solid white",marginLeft:5, marginRight:8,outline:'none',marginTop:4,  backgroundColor:'white'}} onChange={(e)=>setLoanTenure(e.target.value)}/>
       </div>
       <div className="input-fiedl" style={{width:358,border:'1px solid #846CA6',borderRadius:8,backgroundColor:'#FFFFFF',height:48}}>
        <input  className="input-fiedl-inner" placeholder="₹..." style={{width:350,border:"0px solid white",marginLeft:5, marginRight:8,outline:'none',marginTop:4,  backgroundColor:'white'}} onChange={(e)=>setAnnualInterestRate(e.target.value)}/>
       </div>
       <div className="input-fiedl" style={{width:358,border:'1px solid #846CA6',borderRadius:8,backgroundColor:'#59266D',color:'white',height:48,fontFamily:'Archivo',fontWeight:700,fontSize:16,display:'flex', flexDirection:'row',alignItems:'center',justifyContent:'center'}} onClick={()=>calculateLoanEligibility()}>Submit
       </div>
        </div>
        <div className="box-eligiblity-loan-amount" style={{padding:62,backgroundColor:'#846CA6',margin:0,borderTopRightRadius:24,borderBottomRightRadius:24,display:'flex',flexDirection:'column',justifyContent:'center'}}>
          <div style={{backgroundColor:'transparent',display:'flex',flexDirection:'column',gap:16,alignItems:'center'}}>
          <div style={{backgroundColor:'transparent',color:'white',fontFamily:'Archivo',fontWeight:700,fontSize:32}}>Eligible Loan Amount</div>
          <div className="eligible-loan-amount-box-button"  style={{width:400,height:80,borderRadius:16,color:'#59266D',backgroundColor:'white',fontFamily:'Archivo',fontWeight:700,fontSize:40,display:'flex', flexDirection:'row',alignItems:'center',justifyContent:'center'}}>₹{Math.round(eligibility)}
          </div>
          </div>
        </div>
        </div>
        </div>
<div 
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
         Improve Your Loan Eligibility
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
        If you don’t meet the eligibility criteria or want to increase your chances of approval, here are some tips:
        </div>
        <div 
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Improve Your Credit Score: 
            </span>{" "}
     Pay down existing debt, avoid late payments, and limit new credit applications to improve your score.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2.Consolidate Existing Loans:
            </span>{" "}
             If your current debt is too high, consider consolidating your loans to reduce your EMI burden.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.   Add a Co-Applicant:
            </span>{" "}
             Adding a co-applicant, such as a spouse, with a higher income or better credit score can increase your eligibility.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Opt for a Longer Tenure: 
            </span>{" "}
         Choosing a longer repayment tenure can reduce your monthly EMI, making it easier to qualify for a larger loan amount.
          </div>
        </div>
        <div  className="increase-margines" style={{backgroundColor:'#E7E4F9',borderRadius:24,padding:40,marginTop:'2%',marginBottom:'4%'}}>
            <div style={{display:'flex',flexDirection:'column',gap:8,backgroundColor:'transparent'}}>
            <p className="minutes"style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:32,fontWeight:600}}>Check Your Eligibility in Minutes!</p>
            <p style={{color:'#59266D',backgroundColor:'transparent',fontFamily:'Archivo',fontSize:18,fontWeight:400,lineHeight:1.2}}>Get personalized loan options based on your income, current EMI, and credit score. Quick, easy,<br className="display-none"/> and tailored just for you—apply now to see what you qualify for!</p>
            <div style={{display:'flex',flexDirection:'row',gap:24,backgroundColor:'transparent',marginTop:'3%'}}>
                <div style={{backgroundColor:'#553285',color:'white',borderRadius:8,padding:10, paddingLeft:44,paddingRight:44,fontFamily:'Archivo',fontWeight:500,fontSize:18}}> Apply Now</div>

            </div>
            </div>

        </div>
      </div>
    </>
  );
};

export default EligiblityCriteria;
