import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import HowItWorks from "./HowItWorks";
import OurServices from "./OurServices";
import DownloadApp from "./DownloadApp";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    token == null && navigate("/");
    // localStorage.clear();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Check Loan Eligibility, Calculate EMI & Balance Transfer Online |
          Minemi.ai
        </title>
        <meta
          name="description"
          content="Check your loan eligibility instantly without affecting your credit score. Use Minemi’s EMI calculator, balance transfer, and top-up loan tools to compare and optimize personal, home, and car loans. Start saving today with customized financial solutions!"
        />
      </Helmet>
      <HeroSection />
      <HowItWorks />
      <OurServices />
      <DownloadApp />
    </>
  );
};

export default Home;
