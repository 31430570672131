import React, { useEffect, useState } from "react";
import "./BadCredit.css";
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import illustration from "../../Assets/Images/Illustration.webp"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HashLink as RouterHashLink } from "react-router-hash-link";
import BankTable from "./BankTable";



const BadCredit = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const[index,setIndex]=useState(0)

  // Function to check if the device is mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Mobile devices are considered to be 768px or below
  };

  useEffect(() => {
    // Check screen size on component mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };


  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const [openIndex2, setOpenIndex2] = useState(null);

  const toggleAccordion2 = (index) => {
    setOpenIndex2(openIndex2 === index ? null : index);
  };
  return (
    <div>
      <div className="container-fluid"
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "5%",
          paddingRight: "5%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Compare Personal Loan Interest Rates from Top Banks in India
        </div>
        <div>
      {/* <p
        style={{
          fontWeight: 400,
          fontSize: 18,
          color: "#74589B",
          backgroundColor: "#E7E4F9",
          lineHeight: 1.2,
        }}
      >
        {!isMobile || isExpanded ? (
          <>
            Finding a loan with bad Credit can be challenging, but it’s not
            impossible. The best bad Credit loans of October 2024 offer flexible{" "}
            terms, lower interest rates, and fast approval processes for those who
            may not have a high Credit score. Whether you're looking to
            consolidate debt, cover emergency expenses, or finance a major
            purchase, these loans provide viable solutions to meet your needs.
            Our top picks focus on lenders who understand your situation and
            offer more accessible options. Explore these options to find the best
            loan tailored to your financial situation today.
          </>
        ) : (
          <>
            Finding a loan with bad Credit can be challenging, but it’s not
            impossible. The best bad Credit loans of October 2024 offer flexible{" "}
            terms, lower interest rates, and fast approval processes... <span  style={{backgroundColor:'transparent',color:"#59266D"}} onClick={toggleReadMore}> {isExpanded ? "Show Less" : "Read More"}</span> 
          </>
        )}
      </p> */}

    </div>
      </div>
      <div  className="container" style={{marginLeft:'5%',marginRight:'5%',marginTop: 56 }}>
        <p
          style={{
            fontWeight: 600,
            fontSize: 28,
            color: "#1F1F1F",
            lineHeight: 1.2,
          }}
        >
          Suggested loans for bad Credit.
        </p>
        <p
          style={{
            fontWeight: 400,
            fontSize: 16,
            color: "#8A8A8A",
            marginTop: 8,
            lineHeight: 1.2,
          }}
        >
          MinEMI suggests the best loans for bad Credit scores by analyzing
          thousands of companies
        </p>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            border: "1px solid #D9D9D9",
            borderRadius: 12,
            marginTop: 24,
          }}
        >
          <p
            style={{
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              marginTop: 14,
              marginLeft: "1%",
              paddingBottom: 0,
            }}
          >
            Best bad Credit loans for thin Credit
          </p>
       <BankTable />
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
        How Interest Rates Affect EMI Payments
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >Your loan’s interest rate directly affects your monthly EMI (Equated Monthly Installment). A slight difference in interest rates can result in significant savings over time. Use our EMI calculator to estimate your monthly payments and choose the loan that fits your budget
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
 How to Compare Personal Loan Interest Rates
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >When comparing personal loans, interest rates are essential, but they’re not the only factor to consider. Here are the key things you should evaluate when choosing the best loan for you:


        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Interest Rates:
            </span>{" "}
            Lower interest rates mean lower EMIs, but be cautious of promotional rates that may increase after a certain period.       </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2. Processing Fees: 
            </span>{" "}
            Some lenders charge higher processing fees that can significantly affect the overall loan cost.         </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.Loan Tenure:
            </span>{" "}
            A shorter tenure results in higher EMIs but less total interest paid. Longer tenures reduce EMIs but increase total interest.      </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Prepayment Penalties:
            </span>{" "}
              Look for lenders that allow prepayments with little or no penalties, allowing you to save on interest if you decide to pay off the loan early.
     </div>   
        </div>


        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
Tips for Choosing the Best Personal Loan
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >Selecting the right personal loan goes beyond just finding the lowest interest rate. Here are some tips to help you make the best choice for your financial situation:


        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Overall Costs:
            </span>{" "}
            Consider all associated fees such as processing fees, prepayment penalties, and hidden charges.    </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2.Loan Tenure:
            </span>{" "}
            Choose a tenure that balances your EMI affordability with the total interest you’ll pay over time.        </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.Prepayment Flexibility: 
            </span>{" "}
            Look for loans that offer prepayment or foreclosure with minimal fees, which can help you close the loan early and save on interest.    </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Credit Score:
            </span>{" "}
              Look for lenders that allow prepayments with little or no penalties, allowing you to save on interest if you decide to pay off the loan early.Your credit score significantly affects the loan offers and interest rates you can receive. Understand your credit profile before applying.     </div>   
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Bad Credit loan interest rates and fees
        </div>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            border: "1px solid #D9D9D9",
            marginTop: 16,
          }}
        >
          <thead>
            <tr style={{ border: "1px solid #D9D9D9", padding: 4 }}>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Borrow Credit rating
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Score range
              </th>
              <th
                scope="col"
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1F1F1F",
                  padding: 4,
                  textAlign: "left",
                }}
              >
                Estimated APR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Excellent
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                720 - 850
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                11%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Good
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                690 - 718
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                15%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Fair
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Thornton
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                18%
              </td>
            </tr>
            <tr style={{ border: "1px solid #D9D9D9" }}>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                Bad
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                300 - 629
              </td>
              <td
                style={{
                  border: "1px solid #D9D9D9",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "#8A8A8A",
                  padding: 4,
                }}
              >
                22%
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Get a Personal Loan for Bad Credit
        </div>
        <div
          style={{
            marginTop: 24,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Securing a personal loan with bad credit can be challenging, but it's
          not impossible. Here’s a step-by-step guide to help you get started:
        </div>
        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              1.Check Your Credit Score :
            </span>{" "}
            Begin by reviewing your credit score. Knowing where you stand helps
            you understand what loan options are available to you and what
            lenders will likely approve your application.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              2.Explore Lenders for Bad Credit:
            </span>{" "}
            Look for lenders that specialize in bad credit loans. These lenders
            are more willing to work with borrowers who have less-than-perfect
            credit and may offer more flexible terms.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              3.Consider a Co-signer :
            </span>{" "}
            If your credit score is too low, having a co-signer with good credit
            can increase your chances of approval and might help you secure a
            better interest rate.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              4.Prequalify Before Applying :
            </span>{" "}
            Many lenders allow you to prequalify for a loan without affecting
            your credit score. This helps you see what interest rates and terms
            you qualify for before formally applying.
          </div>
          <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 14 }}>
            <span style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              5.Compare Loan Options :
            </span>{" "}
            Compare Loan Options: Once you have multiple offers, compare
            interest rates, fees, loan amounts, and repayment terms. Choose the
            option that best fits your financial situation and repayment
            ability.
          </div>
        </div>
        <div
          style={{
            marginTop: 40,
            marginBottom: 24,
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs)
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            marginBottom: 120,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12, backgroundColor: "#F6F6F6",}}
            >
           Can I negotiate the interest rate on a personal loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Yes, lenders may offer lower interest rates to applicants with higher credit scores or strong financial profiles.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel2-content"
              id="panel2-header"
            >
           How do processing fees affect my loan cost?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
            Processing fees are upfront costs that can increase the overall expense of your loan. Always factor these into your total loan cost calculations.
            </AccordionDetails>
          </Accordion>
        </div>
      <div className="make-it-center increase-margin " style={{marginTop:'-5%', marginBottom:'2%', padding:24,   background: 'linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)',display:'flex',justifyContent:'space-between',flexWrap:'wrap',gap:20,borderRadius:24
}}>

    <div style={{marginLeft:'4%',backgroundColor:'transparent',display:'flex',flexDirection:'column',gap:24,borderRadius:24,alignSelf:'center'}}>
        <p className="eligiblity-criteria" style={{fontFamily:'Archivo',color:'#59266D',fontSize:40,backgroundColor:'transparent'}}><i style={{backgroundColor:'transparent',lineHeight:1.2}}>Ready to find the best personal loan for you? 

<br className="brake-remove"/> Compare rates, check eligibility, and  calculate <br className="brake-remove"/> your EMI with Minemi’s personalized tools.</i></p>
<RouterHashLink to="/#BankTable">
<div className="eligiblity-buttons" style={{backgroundColor:'#553285',color:'#FFFFFF', width:'39%',borderRadius:8,fontFamily:'Archivo',fontWeight:500,fontSize:18,paddingTop:8,paddingBottom:8,textAlign:'center'}}>Compare now</div></RouterHashLink>
    </div>
    <div  style={{width:200,height:195,backgroundColor:'transparent',marginRight:'4%',display:'flex', flexDirection:'row',alignContent:'center'}}>
        <img src={illustration} style={{width:'100%',height:'100%',backgroundColor:'transparent'}} alt=""/>
    </div>
</div>
      </div>
    </div>
  );
};

export default BadCredit;
