import React, { useEffect, useState } from "react";
import HomeLogo from "../../Assets/Images//HomeLogo.webp";
import userLogo from "../../Assets/Images/userLogo.webp";
import "./ConsolidDetail.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";

function TopUpDetail() {
  window.scrollTo(0, 0);
  
  const location = useLocation();
  const {
    loan_tenure,
    maxtopupallowed,
    accountType,
    CurrentBalance,
    Account_Number,
    subscriber,

  } = location.state;
  const [button, setButton] = useState(false);
  const [table, setTable] = useState(false);
  const [TopAmount, setTopAmount] = useState(maxtopupallowed);
  const [irr, setIrr] = useState("");
  const [newIrrs, setNewirrs] = useState("");
  const [newemi, setnewEmi] = useState("");
  const [oddirr, setOddirrs] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true); // Initially, the button is disabled
  // console.log(accountType)
  const navigate = useNavigate();
  const Handler = () => {
    const token=localStorage.getItem("token")
    // console.log("dbhb",accountType)
    setTable(true);
    setButton(true);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/topupSummary`,
      headers: {
        Authorization: `Bearer ${token}`},
           data: {
        "topup-amount": CurrentBalance,
        tenure: loan_tenure,
        accountType: accountType,
        outstandingAmt: TopAmount,
        accountType:accountType
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setnewEmi(response.data.data.summary.new_emi);
        setIrr(response.data.data.formulaUsed.irr);
        setNewirrs(response.data.data.summary.new_interest);
        setOddirrs(response.data.data.summary.current_interest);
        // console.log(response.data.data.summary.current_interest);
      })
      .catch(function (error) {
        // console.error(error);
      });
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  useEffect(()=>{
    const token = localStorage.getItem("token")
    if(token==null){
      navigate("/")
    } else{
      // console.log("user Login")
    }
  },[])
  const formatPrice = (price) => {
    if (!price) return ""; // Handle empty input
    
    // Remove existing commas and convert to string
    const cleanNumber = String(price).replace(/,/g, "");
    
    // Match only numbers
    if (!/^\d+$/.test(cleanNumber)) return "";
    
    // Convert to Indian format
    const lastThree = cleanNumber.substring(cleanNumber.length - 3);
    const otherNumbers = cleanNumber.substring(0, cleanNumber.length - 3);
    
    if (otherNumbers !== '') {
      // Add commas every two digits in the other numbers
      const formatted = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return formatted + "," + lastThree;
    }
    
    return lastThree;
  };
  return (
    <div className="container">
      <div style={{ textAlign: "center" }}>
        <div
          className="congratulation"
          style={{
            fontSize: "48px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "60px",
          }}
        >
          Congratulations!
        </div>
        <div
          className="Saving"
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "25px",
            letterSpacing: "0em",
            textAlign: "center",
            color: " #8A8A8A",
            marginTop: "35px",
            marginBottom: "40px",
          }}
        >
          You Can avail Top-Up to Rs.{formatNumberWithCommas(maxtopupallowed)}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "24px",
        }}
      >
        <div
          style={{
            width: "92%",
            height: "270px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 1)",
            paddingBottom:'5%'
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "50px",
              paddingBottom: "10px",
              backgroundColor: "transparent",
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
                backgroundColor: "transparent",
              }}
            >
              {accountType == "05" ? (
                <>
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "27px",
                      backgroundColor: "rgba(85, 50, 133, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={userLogo}
                      alt=""
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "transparent",
                        position: "relative",
                        top: "7px",
                      }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(31, 31, 31, 1)",
                        paddingTop: "10px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Personal Loan
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineheight: "13px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(138, 138, 138, 1)",
                        paddingTop: "5px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {subscriber}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "27px",
                      backgroundColor: "rgba(85, 50, 133, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={HomeLogo}
                      alt=""
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "transparent",
                        position: "relative",
                        top: "7px",
                      }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(31, 31, 31, 1)",
                        paddingTop: "10px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Housing Loan
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineheight: "13px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(138, 138, 138, 1)",
                        paddingTop: "5px",
                        backgroundColor: "transparent",
                      }}
                    >
                      { subscriber }
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <div style={{ backgroundColor: "transparent" }}>
              <div
                style={{
                  fontsize: "14px",
                  fontweight: "600",
                  lineheight: "15px",
                  letterspacing: "0em",
                  textalign: "left",
                  paddingTop: "30px",
                  paddingLeft: "20px",
                  paddingBottom: "5px",

                  backgroundColor: "transparent",
                }}
              >
                Required Top-Up Amount (Rs)
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "13px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  paddingLeft: "20px",
                  paddingTop: "3px",
                }}
              >
                Max: Rs {formatNumberWithCommas(maxtopupallowed)}
              </div>
            </div>

            <input
              placeholder="Rs. 00,000"
              type="text"
              onChange={(e) => {
                setTopAmount(e.target.value);
                setButtonDisabled(e.target.value == "");   
              }}
              value={formatPrice(TopAmount)}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "17px",
                letterSpacing: "0em",
                display: "flex",
                paddingLeft: "20px",
                alignItems: "center",
                color: "#8A8A8A",
                width: "100px",
                height: "55px",
                padding: "16px, 80px, 15px, 16px",
                borderRadius: "8px",
                border: "1px solid #846CA6",
                marginTop: "30px",
                marginRight: "10px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "16px",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: " #59266D",
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              Tenure
            </div>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              {loan_tenure} Month
            </div>
          </div>
          <div
            style={{
              backgroundColor: "transparent",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <button  
              disabled={TopAmount==""}

              style={{
                backgroundColor: "rgb(89, 38, 109)",
                height:56,
                width:'92%',
                outline: "none",
                border: "none",
                color: "white",
                borderRadius: "10px",
                cursor: "pointer",
                opacity: TopAmount=="" ? "0.5" : "", // Set opacity to 1 when disabled, otherwise leave it empty
              }}
              onClick={Handler}
            >
              Proceed Top-up
            </button>
          </div>
        </div>
        {table && (
          <table
            style={{
              width: "358px",
              height: "160px",
              borderRadius: "10px",
              background: "#FFFFFF",
            }}
          >
            <tr style={{ backgroundColor: "transparent" }}>
              <th
                style={{
                  padding: "7px 26px",
                  backgroundColor: "transparent",
                  width: "119px",
                  height: "40px",
                  borderTopLeftRadius: "10px",
                  borderLeft: "1px solid #E0E0E0",
                  borderTop: "1px solid #E0E0E0",
                }}
              ></th>
              <th
                style={{
                  borderTopRightRadius: "4px",
                  fontSize: "14px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  color: "#FFFFFF",
                  backgroundColor: "#3A868B",
                  width: "150.47px",
                  height: "40.49px",
                }}
              >
                New
              </th>
            </tr>
            <tr style={{ border: "1px solid #E0E0E0" }}>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#000000",
                  border: "1px solid #E0E0E0",
                  borderLeft: "none",
                  borderRight: "none",
                  borderLeft: "1px solid #E0E0E0",
                }}
              >
                Tenure
              </td>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: " #3A868B ",
                  border: "1px solid #3A868B ",
                  // borderBottom:'none'
                }}
              >
                {loan_tenure}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: "#000000",
                  backgroundColor: " #FFFFFF",
                  border: "1px solid #E0E0E0",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "1px solid #E0E0E0",
                  borderBottom: "none",
                }}
              >
                Interest
              </td>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  color: " #3A868B ",
                  backgroundColor: " #FFFFFF",
                  border: "1px solid #3A868B ",
                  borderTop: "none",
                  // borderBottom:'none'
                }}
              >
                {newIrrs}%
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  border: "1px solid #E0E0E0",
                  borderLeft: "1px solid #E0E0E0",
                  borderRight: "none",
                  borderBottomLeftRadius: "10px",
                  borderBottom: "1px solid #E0E0E0",
                }}
              >
                EMI
              </td>
              <td
                style={{
                  borderLeft: "1px solid #3A868B",
                  border: "1px solid #3A868B ",
                  borderTop: "none",
                  borderBottom: "1px solid #3A868B",
                  borderBottomRightRadius: "10px",
                  textAlign: "center",
                  color: " #3A868B ",
                  fontSize: "12px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                }}
              >
                {" "}
                ₹{formatNumberWithCommas(newemi)}
              </td>
            </tr>
          </table>
        )}
      </div>
      {button && (
        <>
          {accountType == "05" ? (
            <Link
              to="/personalloanform"
              state={{
                newIrr: irr,
                tenure: loan_tenure,
                accountType: accountType,
                Account_Number: Account_Number,
                loanAmount: CurrentBalance,
                oldIrr: oddirr,
              }}
            >
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                <button
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "center",

                   width:'92%',
                   height:56,
                    borderRadius: "10px",
                    backgroundColor: "rgba(89, 38, 109, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                 Process Top-up
                </button>
              </div>
            </Link>
          ) : (
            <Link
              to="/homeloanform"
              state={{
                newIrr: irr,
                tenure: loan_tenure,
                accountType: accountType,
                Account_Number: Account_Number,
                loanAmount: CurrentBalance,
                oldIrr: oddirr,
              }}
            >
              <div style={{ textAlign: "center", marginTop: "60px" }}>
                <button
                  className="Consolidate-btn"
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "17px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    height:56,
                    width:'92%',
                    borderRadius: "10px",
                    backgroundColor: "rgba(89, 38, 109, 1)",
                    color: "rgba(255, 255, 255, 1)",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Initiate Process
                </button>
              </div>
            </Link>
          )}
        </>
      )}
    </div>
  );
}

export default TopUpDetail;
