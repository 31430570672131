import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function TermsandCondition() {
  const [htmlContent, setHtmlContent] = useState("");

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.term_of_use);
        // console.log(response.data.data.
        //   term_of_use)
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <>
      <Helmet>
        <title>Terms & Conditions for App Use - Minemi</title>
        <meta
          name="description"
          content="Review our terms and conditions for using our app to manage loans, check eligibility, track EMIs, and access loan-related services."
        />
      </Helmet>
      <div
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          marginTop: 70,
          fontSize: "21px",
          lineHeight: "3.75rem",
          color: "rgb(89, 38, 109)",
          fontWeight: "bold",
        }}
      >
        Terms and Conditions{" "}
      </div>
      <div
        className="api-text"
        style={{ marginLeft: "5%", marginRight: "5%" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}

export default TermsandCondition;
