import React, { useEffect, useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Chart } from "react-google-charts";
import axios from "axios";
import "./Apex.css";
function ApexChart() {
  const [series1, setSeries1] = useState("");
  const [series2, setSeries2] = useState("");
  const [series3, setSeries3] = useState("");
  const [series4, setSeries4] = useState("");
  const[name1,setName1]=useState("")
  const[name2,setName2]=useState("")
  const[name3,setName3]=useState("")
  const[name4,setName4]=useState("")
  const HandleClick = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/loanOverview`,
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data)
        setSeries1(response.data.data.loanChart[0].LoanPercentage);
        setSeries2(response.data.data.loanChart[1].LoanPercentage);
        setSeries3(response.data.data.loanChart[2].LoanPercentage);
        setSeries4(response.data.data.loanChart[3].LoanPercentage);
        setName1(response.data.data.loanChart[0].loan_Category);
        setName2(response.data.data.loanChart[1].loan_Category);
        setName3(response.data.data.loanChart[2].loan_Category);
        setName4(response.data.data.loanChart[3].loan_Category);

      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
     HandleClick();
  }, []);
  const data = [
    ["Task", "Hours per Day"],
    [name1, series1],
    [name2,series2 ],
    [name3, series3],
    [name4,series4], // CSS-style declaration
  ];
  const options = {
    colors: ['#FFF500', '#70FF00', '#FF6B00', '#00D1FF'],
    legend: "none",
    pieHole: 0.4,
    is3D: false,
  };

  return (
    <div style={{ backgroundColor: "transparent" }}>
      <Chart 
        style={{
          backgroundColor: "transparent",
          fill: "red",
        }}
        chartType="PieChart"
        fill="red"
        width={140}
        height="100px"
        data={data}
        options={options}
       
      />
    </div>
  );
}

export default ApexChart;
