import React,{useEffect} from "react";
import HomeLogo from "../../Assets/Images//HomeLogo.webp";
import Bank from "../../Assets/Images/Bank.webp";
import "./ConsolidDetail.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import userLogo from "../../Assets/Images/userLogo.webp";

function  LowerEmiDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const {oldIrr,newIrr,loanAmount,tenure, oldemi,newemi, saveonemi, accountType,subscriber,savepercentage,Account_Number, } = location.state;
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  const token = localStorage.getItem("token")
  useEffect(()=>{
    const token = localStorage.getItem("token")
    if(token==null){
      navigate("/")
    } else{
      // console.log("user Login")
    }
  },[])
  console.log("id------>",id)
  return (
    <div className="container">
      <div style={{ textAlign: "center" }}>
        <div
          className="Saving"
          style={{
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "38px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "25px",
          }}
        >
          Your are saving
        </div>
        <div
          className="price-detail"
          style={{
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "58px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "rgba(89, 38, 109, 1)",
            marginTop: "10px",
          }}
        >
          ₹{formatNumberWithCommas(saveonemi)}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "25px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#8A8A8A",

            marginTop: "20px",
          }}
        >
          This is {savepercentage}% of Outstanding Amount
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "24px",
        }}
      >
        <div
          className="boxLoan"
          style={{
            width: "358px",
            height: "160px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "50px",
              paddingBottom: "10px",
              backgroundColor: "transparent",
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              borderBottom: " 1px solid rgba(0, 0, 0, 0.08)",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "12px",
                backgroundColor: "transparent",
              }}
            >
              {accountType === "02" ? (
                <>
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "27px",
                      backgroundColor: "rgba(85, 50, 133, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={HomeLogo}
                      alt=""
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "transparent",
                        position: "relative",
                        top: "7px",
                      }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(31, 31, 31, 1)",
                        paddingTop: "10px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Home Loan
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineheight: "13px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(138, 138, 138, 1)",
                        paddingTop: "5px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {subscriber}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "27px",
                      backgroundColor: "rgba(85, 50, 133, 0.1)",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={userLogo}
                      alt=""
                      style={{
                        width: "32px",
                        height: "32px",
                        backgroundColor: "transparent",
                        position: "relative",
                        top: "7px",
                      }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(31, 31, 31, 1)",
                        paddingTop: "10px",
                        backgroundColor: "transparent",
                      }}
                    >
                      Personal Loan
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineheight: "13px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: "rgba(138, 138, 138, 1)",
                        paddingTop: "5px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {subscriber}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: " rgba(31, 31, 31, 1)",
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              Outstanding
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              ₹{formatNumberWithCommas(loanAmount)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "transparent",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: " rgba(31, 31, 31, 1)",
                paddingTop: "20px",
                paddingLeft: "20px",
              }}
            >
              Tenure
            </div>
            <div
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "15px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "rgba(89, 38, 109, 1)",
                backgroundColor: "transparent",
                paddingTop: "20px",
                paddingRight: "20px",
              }}
            >
              {tenure} Months
            </div>
          </div>
        </div>
        <table className="boxLoan"
          style={{
            width: "358px",
            height: "160px",
            borderRadius: "10px",
            background: "#FFFFFF",
          }}
        >
          <tr style={{ backgroundColor: "transparent" }}>
            <th
              style={{
                padding: "7px 26px",
                backgroundColor: "transparent",
                width: "119px",
                height: "40px",
                borderTopLeftRadius: "10px",
                borderLeft: "1px solid #E0E0E0",
                borderTop: "1px solid #E0E0E0",
              }}
            ></th>
            <th
              style={{
                backgroundColor: "transparent",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#000000",
                width: "119px",
                height: "34px",
                border: "1px solid #E0E0E0",
                borderRight: "none",
                borderTop: "none",
                borderTop: "1px solid #E0E0E0",
                borderBottom: "none",
              }}
            >
              Current
            </th>
            <th
              style={{
                borderTopRightRadius: "4px",
                fontSize: "14px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                color: "#FFFFFF",
                backgroundColor: "#3A868B",
                width: "119.47px",
                height: "40.49px",
              }}
            >
              New
            </th>
          </tr>
          <tr style={{ border: "1px solid #E0E0E0" }}>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                border: "1px solid #E0E0E0",
                borderLeft: "none",
                borderRight: "none",
                borderLeft: "1px solid #E0E0E0",
              }}
            >
              Interest
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                borderTop: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              {oldIrr}%
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                border: "1px solid #3A868B ",
              }}
            >
              {newIrr}%
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                backgroundColor: " #FFFFFF",
                border: "1px solid #E0E0E0",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "1px solid #E0E0E0",
              }}
            >
              EMI
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#000000",
                backgroundColor: " #FFFFFF",
                border: "1px solid #E0E0E0",
                borderTop: "none",
              }}
            >
              {" "}
              ₹{formatNumberWithCommas(oldemi)}
            </td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                backgroundColor: " #FFFFFF",
                border: "1px solid #3A868B ",
                borderTop: "none",
              }}
            >
              {" "}
              ₹{formatNumberWithCommas(newemi)}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                border: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",

                borderBottom: "1px solid #E0E0E0",
                borderRight: "none",
                borderBottomLeftRadius: "10px",
              }}
            >
              Total Savings
            </td>
            <td
              style={{
                border: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",
                borderBottom: "none",
                borderTop: "none",
                borderBottom: "1px solid #E0E0E0",
              }}
            ></td>
            <td
              style={{
                fontSize: "12px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: " #3A868B ",
                border: "1px solid #3A868B ",
                borderTop: "none",
                borderBottomRightRadius: "10px",
              }}
            >
              {" "}
              ₹{formatNumberWithCommas(saveonemi)}
            </td>
          </tr>
        </table>
      </div>
      <div style={{ textAlign: "center", marginTop: "60px" }}>

        {accountType=="05"?(   <Link
          to="/personalloanform"
          state={{
            loanAmount: loanAmount,
            oldIrr: oldIrr,
            tenure: tenure,
            newIrr: newIrr,
            accountType: accountType,
            Account_Number: Account_Number,
          }}
        >
          <button
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              height:56,
              borderRadius: "10px",
              backgroundColor: "rgba(89, 38, 109, 1)",
              color: "rgba(255, 255, 255, 1)",
              border: "none",
              cursor: "pointer",
              width:'92%',
              textAlign:'center'
            }}
          >
           {id==1?" Initiate Process ":"Initiate  Consolidate Process"}
          </button>
        </Link>):(   <Link
          to="/homeloanform"
          state={{
            loanAmount: loanAmount,
            oldIrr: oldIrr,
            tenure: tenure,
            newIrr: newIrr,
            accountType: accountType,
            Account_Number: Account_Number,
          }}
        >
          <button
            style={{
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              height:56,
              borderRadius: "10px",
              backgroundColor: "rgba(89, 38, 109, 1)",
              color: "rgba(255, 255, 255, 1)",
              border: "none",
              cursor: "pointer",
              width:'92%',
              textAlign:'center'
            }}
          >
       {id==1?" Initiate Process":"Initiate  Consolidate Process"}
          </button>
        </Link>)}
       
      </div>
    </div>
  );
}

export default LowerEmiDetail;
