import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function BalanceTransfer() {
  const [htmlContent, setHtmlContent] = useState("");

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.balance_transfer);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <>
      <Helmet>
        <title>Loan Balance Transfer with Savings Calculator | Minemi</title>
        <meta
          name="description"
          content="Transfer your loan to a lower interest rate at Minemi.ai. Use our balance transfer savings calculator and reduce your EMIs instantly.
"
        />
      </Helmet>
      <div
        className="api-text"
        style={{ marginLeft: "5%", marginRight: "5%" }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
}

export default BalanceTransfer;
