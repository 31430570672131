import React from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Bill from "../../Assets/Images/Bill Get.webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import image from "../../Assets/Images/image.webp";
import image2 from "../../Assets/Images/image (1).webp";
import image3 from "../../Assets/Images/image (2).webp";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper"; // Assuming Item is a Paper component

const Carloan = () => {
  const blogs = [
    {
      id: 1,
      image: image,
      title: "How to Get a Personal Loan in India",
      description:
        "Applying for a personal loan has never been easier. Follow these five simple steps to get started and access funds in no time.Read More.",
    },
    {
      id: 2,
      image: image2,
      title: "What Credit Score Do You Need for a Personal Loan?",
      description:
        "Learn the key steps to speed up your personal loan approval process. Get tips on how to prepare the right documents and increase your chances of quick approval. Read More.",
    },
    {
      id: 3,
      image: image3,
      title: "Documents Required for a Personal Loan",
      description:
        "Need to fund a personal project or emergency? Here are the top five reasons why a personal loan could be your best financial option. Read More.",
    },
    {
      id: 4,
      image: image3,
      title: "How to Get Approved for a Personal Loan Quickly",
      description:
        "Learn the key steps to speed up your personal loan approval process. Get tips on how to prepare the right documents and increase your chances of quick approval. Read More.",
    },
    {
      id: 5,
      image: image2,
      title: "Top 5 Reasons to Choose a Personal Loan",
      description:
        "Need to fund a personal project or emergency? Here are the top five reasons why a personal loan could be your best financial option. Read More.",
    },
    {
      id: 6,
      image: image,
      title: "5 Easy Steps to Apply for a Personal Loan",
      description:
        "Applying for a personal loan has never been easier. Follow these five simple steps to get started and access funds in no time.Read More.",
    },
  ];
  const data = [
    {
      id: 1,
      title: "Can You Pay Off a Personal Loan Early?",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2024/09/GettyImages-1467959913-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    },
    {
      id: 2,
      title: "EarnIn: Can You Pay Off a Personal Loan Early?",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2023/01/EarnIn_Logo_RGB-1-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    },
    {
      id: 3,
      title: "How Personal Loans Work",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2024/05/GettyImages-2007594509-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    },
    {
      id: 4,
      title: "What Are The Best Loan Repayment Options?",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2024/07/GettyImages-1353790105-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    },
    {
      id: 5,
      title: "The Future of Personal Loan Interest Rates",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2024/05/GettyImages-1369079622-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    },
    {
      id: 6,
      title: "Loan Consolidation: How It Works",
      imgSrc: "https://www.nerdwallet.com/assets/blog/wp-content/uploads/2024/05/GettyImages-1449325402-480x288.jpg",
      authorImg: "https://www.nerdwallet.com/tachyon/2022/05/Annie-Millerbernd-Headshot.webp?resize=70%2C70",
      authorName: "Annie Millerbernd",
    }
  ];
  
  return (
    <>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "5%",
          paddingRight: "5%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 48,
          paddingBottom: 80,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        <div className="homeloan-heading"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
Get the Best Car Loan Deals from Top Banks in India       </div>
        <p className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 500,
            fontSize: 24,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
Compare car loan offers, check your eligibility, and calculate EMIs to get the most suitable loan for buying your dream car or a used car.
        </p>
      </div>
      <div
        className="make-wrap"
        style={{
          marginTop: 56,
          marginLeft: "2%",
          marginRight: "2%",
          display: "flex",
          flexDirection: "row",
          gap: 16,
        }}
      >
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 10,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
             Check Eligibility
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
          Find out how much you can borrow based on your credit score and income.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={money}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              Check your eligibility for car loans instantly with top lenders.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
              Compare Offers
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
          Compare car loan offers from top banks
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Ratio}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
            Compare interest rates, processing fees, and other terms to find the best deal.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
             Car Loan Top-Up
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
             Increase your car loan amount with ease.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Group}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
             Explore top-up options on your current car loan from leading lenders.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
        Used Car Loans
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Get the best deals on used car loans.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={creditScore}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
             Looking for a used car loan? Compare options from top banks for competitive interest rates and flexible tenure.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
           Loan Status Tracking
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            Track the status of your car loan application.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Vector}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
             Check the current status of your car loan application with our easy tracker.
            </p>
          </p>
        </div>
        <div
          className="width-adjust"
          style={{
            backgroundColor: "#EDEBEE",
            padding: 12,
            borderRadius: 12,
            border: "1px solid #E7E4F9",
            width: 290,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 500,
              color: "#1F1F1F",
              backgroundColor: "transparent",
              fontSize: 18,
            }}
          >
          EMI Calculator
          </p>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#8A8A8A",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
           Calculate your monthly EMI.
          </p>
          <div
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              marginTop: 32,
              marginBottom: 19,
              marginBottom: 19,
            }}
          >
            <div
              style={{ width: 56, height: 56, backgroundColor: "transparent" }}
            >
              <img
                src={Bill}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              />
            </div>
          </div>
          <p
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              fontWeight: 400,
              color: "#846CA6",
              backgroundColor: "transparent",
              marginTop: 8,
              fontSize: 14,
            }}
          >
            {" "}
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
              }}
            >
              {" "}
              Use our car loan EMI calculator to estimate your monthly repayments.
            </p>
          </p>
        </div>
      </div>
      <div
        style={{
          maginTop: 64,
          marginLeft: "5%",
          marginRight: "5%",
          marginTop: 32,
        }}
      >
        <p
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
         Qualify for a Personal Loan
        </p>
      </div>
      <div
        style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
        className="loan-list-container"
      >
        {blogs?.map((blog) => (
          <div className="loan-container" key={blog.id}>
            <img  src={blog.image} alt={blog.heading} className="loan-image" />
            <h2
              className="loan-heading"
              style={{ fontFamily: "Archivo", fontWeight: 600, fontSize: 18 }}
            >
              {blog.title}
            </h2>
            <div
              className="loan-subheading"
              dangerouslySetInnerHTML={{ __html: blog.description }}
              style={{
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 14,
                color: "#B1B1B1",
              }}
            />
          </div>
        ))}
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
        <p
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          Manage your loan
        </p>
        <div style={{ marginTop: 40, marginBottom: 32 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={5}>
              <Item style={{ border: 0, boxShadow: "0px 0px" }}>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 8 }}
                >
                  <div style={{ height: "auto" }}>
                    <img
                      src="https://www.nerdwallet.com/assets/blog/wp-content/uploads/2019/11/GettyImages-951450866-600x360.jpg"
                      style={{
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 8 }}
                  >
                    <p style={{ fontSize: 18, fontWeight: 400 }}>
                      How To Manage Your Personal Loan
                    </p>
                    <p
                      style={{ fontSize: 16, fontWeight: 400, lineHeight: 1.3 }}
                    >
                      You got the loan - now make a plan to successfully pay it
                      off. Start by adjusting your budget to cover monthly loan
                      payments.
                    </p>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={12} md={7}>
              <Item
                style={{
                  border: 0,
                  boxShadow: "0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 32,
                  backgroundColor: "#F6F6F6",
                }}
              >
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "row", gap: 12 }}
                  >
                    <div style={{ height: 90, width: "100%", maxWidth: 190 }}>
                      <img
                        src="https://www.nerdwallet.com/assets/blog/wp-content/uploads/2020/03/GettyImages-1124849420-480x288.jpg"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 12,
                      }}
                    >
                      <p style={{ fontSize: 18, fontWeight: 400 }}>
                        Can You Pay Off a Personal Loan Early?
                      </p>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis placeat, sapiente dignissimos accusamus laudantium
                      </p>
                    </div>
                  </div>
                ))}
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 40 }}>
        <p
          style={{
            color: "#59266D",
            fontWeight: 600,
            fontFamily: "Archivo",
            fontSize: 28,
            lineHeight: 1,
          }}
        >
          The Latest
        </p>
        <Grid container spacing={2} style={{ marginTop: "2%" }}>
  {data.map((item) => (
    <Grid item xs={12} sm={6} key={item.id}>
      <Item style={{ boxShadow: "0px 0px", marginBottom: 24 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              maxWidth: 170,
            }}
          >
            <img
              src={item.imgSrc}
              style={{
                width: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 12,
              alignSelf: "center",
            }}
          >
            <p style={{ fontSize: 18, fontWeight: 400 }}>{item.title}</p>
          <p style={{fontFamily:'Archivo',fontWeight:400,fontSize:14,marginBottom:0,color:'grey'}}>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Non aspLorem ipsum dolor,
             sit amet consectetur adipisicing</p>
          </div>
        </div>
      </Item>
    </Grid>
  ))}
</Grid>


      </div>
    </>
  );
};

export default Carloan;
