import * as Yup from 'yup';

export const SignUpschemas = Yup.object({
    name: Yup.string()
        .min(2, 'Name must be at least 2 characters')
        .max(25, 'Name must be at most 25 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Enter valid name ')
        .required('Please enter your name'),


        Message: Yup.string()
        .required('message is required'),    
        

    lastname: Yup.string()
        .min(2, 'Last name must be at least 2 characters')
        .max(25, 'Last name must be at most 25 characters')
        .matches(/^[a-zA-Z\s]*$/, 'Enter valid last name')
        .required('Please enter your last name'),
        number: Yup.string()
        .matches(/^\+?\d{1,3}?[-.\s]?\(?(?:\d{2,3})\)?[-.\s]?\d\d\d[-.\s]?\d\d\d\d$/, 'Invalid phone number format')
        .min(13, 'Number must be at least 10 characters')
        .max(13, 'Number must be at most 10 characters')
        .required('Please enter your phone number'),

       
      
  
        email: Yup.string()
      .email('Invalid email')
  .required('Email is required')
    .test('is-valid-domain', 'Invalid email domain', value => {
      if (!value) return false; // Skip validation if value is empty
      const parts = value.split('@');
      if (parts.length !== 2) return false; // Invalid email format
      const domainParts = parts[1].split('.');
      if (domainParts.length < 2) return false; // Missing domain extension
      const domainExtension = domainParts[1];
      return /[a-zA-Z]/.test(domainExtension); // Check if domain extension has at least one alphabet character
    }),
            outstanding: Yup.number()
              .required("Please Enter Valid detail")
              .positive("Please Enter Valid detail")
              .integer("Please Enter Valid detail"),
            tenure: Yup.number()
              .required("Please Enter Valid detail")
              .positive("Please Enter Valid detail")
              .integer("Please Enter Valid detail"),
            emi: Yup.number()
              .required("Please Enter Valid detail")
              .positive("Please Enter Valid detail")
              .integer("EPlease Enter Valid detail"),
    
});
